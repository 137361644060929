import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreModule } from '../core.module';


@Injectable({
  providedIn: CoreModule
})
export class SpinService {

  private requestCount$ = new BehaviorSubject(0);
  
  public showSpinner$ = this.requestCount$.pipe(
    map((count) => count > 0)
  );
  
  constructor() {
  }

  public incrementCount() {
    const count = this.requestCount$.value;
    this.requestCount$.next(count + 1);
  }

  public decrementCount() {
    const count = this.requestCount$.value;
    this.requestCount$.next(count - 1);
  }

}



