export const OSM_DEFAULT_LAYERS: any[] = [
    {
        "id": "background",
        "type": "background",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "background-color": {
                "stops": [
                    [
                        6,
                        "hsl(47,79%,94%)"
                    ],
                    [
                        14,
                        "hsl(42,49%,93%)"
                    ]
                ]
            }
        }
    },
    {
        "id": "landscape",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "globallandcover",
        "maxzoom": 8,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": [
                "match",
                [
                    "get",
                    "class"
                ],
                "crop",
                "hsl(50,67%,86%)",
                "scrub",
                "hsl(97,51%,80%)",
                "grass",
                "hsl(75,51%,85%)",
                "hsl(0,0%,100%)"
            ],
            "fill-opacity": {
                "stops": [
                    [
                        0,
                        1
                    ],
                    [
                        8,
                        0.1
                    ]
                ]
            },
            "fill-antialias": true
        },
        "filter": [
            "all",
            [
                "in",
                "class",
                "crop",
                "grass",
                "scrub"
            ]
        ]
    },
    {
        "id": "snow",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "landcover",
        "maxzoom": 24,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": "hsl(0,0%,100%)",
            "fill-opacity": {
                "stops": [
                    [
                        0,
                        1
                    ],
                    [
                        10,
                        0.7
                    ]
                ]
            },
            "fill-antialias": true
        },
        "filter": [
            "all",
            [
                "==",
                "class",
                "ice"
            ]
        ]
    },
    {
        "id": "forest",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "globallandcover",
        "maxzoom": 8,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": [
                "match",
                [
                    "get",
                    "class"
                ],
                "forest",
                "hsl(119,38%,76%)",
                "tree",
                "hsl(99,42%,76%)",
                "hsl(0,0%,100%)"
            ],
            "fill-opacity": {
                "stops": [
                    [
                        1,
                        0.8
                    ],
                    [
                        8,
                        0
                    ]
                ]
            },
            "fill-antialias": true
        },
        "filter": [
            "all",
            [
                "in",
                "class",
                "forest",
                "tree"
            ]
        ]
    },
    {
        "id": "aeroway_fill",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "aeroway",
        "minzoom": 11,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": "hsl(0,0%,93%)",
            "fill-opacity": 1
        },
        "metadata": {},
        "filter": [
            "==",
            "$type",
            "Polygon"
        ]
    },
    {
        "id": "landcover",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "landcover",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": [
                "match",
                [
                    "get",
                    "class"
                ],
                "wood",
                "hsl(99,48%,77%)",
                "grass",
                "hsl(103,40%,85%)",
                "sand",
                "hsl(52,93%,89%)",
                "hsl(0,0%,100%)"
            ],
            "fill-opacity": 0.5,
            "fill-antialias": false
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "class",
                "grass",
                "sand",
                "wood"
            ]
        ]
    },
    {
        "id": "landuse_industrial",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "landuse",
        "maxzoom": 24,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": [
                "interpolate",
                [
                    "linear"
                ],
                [
                    "zoom"
                ],
                9,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "industrial"
                    ],
                    "hsl(40,67%,90%)",
                    "quarry",
                    "hsla(32, 47%, 87%, 0.2)",
                    "hsla(60, 31%, 87%, 1)"
                ],
                16,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "industrial"
                    ],
                    "hsl(49,54%,90%)",
                    "quarry",
                    "hsla(32, 47%, 87%, 0.5)",
                    "hsla(60, 31%, 87%, 1)"
                ]
            ],
            "fill-opacity": [
                "step",
                [
                    "zoom"
                ],
                1,
                9,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    "quarry",
                    0,
                    1
                ],
                10,
                1
            ]
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "class",
                "industrial",
                "quarry"
            ]
        ]
    },
    {
        "id": "landuse_residential",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "landuse",
        "maxzoom": 24,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": {
                "base": 1,
                "stops": [
                    [
                        4,
                        "hsl(44,34%,87%)"
                    ],
                    [
                        16,
                        "hsl(54, 45%, 91%)"
                    ]
                ]
            }
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "class",
                "residential",
                "suburbs",
                "neighbourhood"
            ]
        ]
    },
    {
        "id": "road_area_pedestrian",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": "hsl(43,100%,99%)",
            "fill-opacity": 0.7
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "Polygon"
            ],
            [
                "!has",
                "brunnel"
            ],
            [
                "!in",
                "class",
                "bridge",
                "pier"
            ],
            [
                "in",
                "subclass",
                "pedestrian",
                "platform"
            ]
        ]
    },
    {
        "id": "landuse",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "landuse",
        "minzoom": 9,
        "maxzoom": 24,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": [
                "match",
                [
                    "get",
                    "class"
                ],
                [
                    "school",
                    "college",
                    "university"
                ],
                "hsl(194,52%,94%)",
                [
                    "stadium",
                    "pitch"
                ],
                "hsl(94,100%,88%)",
                "hospital",
                "hsl(12,63%,94%)",
                "cemetery",
                "hsl(0,0%,88%)",
                "garages",
                "hsl(0,0%,93%)",
                "dam",
                "hsl(68,20%,79%)",
                "hsl(0,0%,100%)"
            ],
            "fill-opacity": {
                "stops": [
                    [
                        9,
                        0.25
                    ],
                    [
                        16,
                        1
                    ]
                ]
            },
            "fill-antialias": true
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "class",
                "cemetery",
                "college",
                "dam",
                "hospital",
                "garages",
                "pitch",
                "school",
                "stadium",
                "university"
            ]
        ]
    },
    {
        "id": "waterway_tunnel",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "waterway",
        "minzoom": 14,
        "layout": {
            "line-cap": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(210,73%,78%)",
            "line-width": {
                "base": 1.3,
                "stops": [
                    [
                        12,
                        0.5
                    ],
                    [
                        20,
                        6
                    ]
                ]
            },
            "line-opacity": 0.5,
            "line-dasharray": [
                2,
                4
            ]
        },
        "filter": [
            "all",
            [
                "==",
                "brunnel",
                "tunnel"
            ]
        ]
    },
    {
        "id": "waterway_river",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "waterway",
        "layout": {
            "line-cap": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(210,73%,78%)",
            "line-width": {
                "stops": [
                    [
                        12,
                        0.5
                    ],
                    [
                        20,
                        6
                    ]
                ]
            }
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "!=",
                "brunnel",
                "tunnel"
            ]
        ]
    },
    {
        "id": "water",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "water",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": [
                "match",
                [
                    "get",
                    "intermittent"
                ],
                1,
                "hsl(205,91%,83%)",
                "hsl(204,92%,75%)"
            ],
            "fill-opacity": [
                "match",
                [
                    "get",
                    "intermittent"
                ],
                1,
                0.85,
                1
            ],
            "fill-antialias": true
        },
        "metadata": {},
        "filter": [
            "all"
        ]
    },
    {
        "id": "aeroway",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "aeroway",
        "minzoom": 11,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,100%)",
            "line-width": [
                "interpolate",
                [
                    "linear",
                    1
                ],
                [
                    "zoom"
                ],
                11,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "runway"
                    ],
                    3,
                    0.5
                ],
                20,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "runway"
                    ],
                    16,
                    6
                ]
            ]
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ]
        ]
    },
    {
        "id": "aeroway_runway",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "aeroway",
        "minzoom": 11,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": "hsl(0,0%,97%)",
            "fill-opacity": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "class",
                "runway"
            ]
        ]
    },
    {
        "id": "aeroway_helipad",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "aeroway",
        "minzoom": 11,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": "hsl(0,0%,100%)",
            "fill-opacity": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "class",
                "helipad"
            ]
        ]
    },
    {
        "id": "ferry_line",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": {
                "stops": [
                    [
                        10,
                        "hsl(205,61%,63%)"
                    ],
                    [
                        16,
                        "hsl(205,67%,47%)"
                    ]
                ]
            },
            "line-width": 1.1,
            "line-dasharray": [
                2,
                2
            ]
        },
        "filter": [
            "all",
            [
                "in",
                "class",
                "ferry"
            ]
        ]
    },
    {
        "id": "tunnel-casing",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 4,
        "layout": {
            "line-cap": "butt",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": [
                "match",
                [
                    "get",
                    "class"
                ],
                "motorway",
                "hsl(28,72%,69%)",
                [
                    "trunk",
                    "primary"
                ],
                "hsl(28,72%,69%)",
                "hsl(36,5%,80%)"
            ],
            "line-width": [
                "interpolate",
                [
                    "linear",
                    2
                ],
                [
                    "zoom"
                ],
                6,
                0,
                7,
                0.5,
                10,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        0,
                        2.5
                    ],
                    [
                        "trunk",
                        "primary"
                    ],
                    2,
                    0
                ],
                12,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        2,
                        6
                    ],
                    [
                        "trunk",
                        "primary"
                    ],
                    3,
                    [
                        "secondary",
                        "tertiary"
                    ],
                    2,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    1,
                    0.5
                ],
                14,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        5,
                        8
                    ],
                    [
                        "trunk"
                    ],
                    4,
                    [
                        "primary"
                    ],
                    6,
                    [
                        "secondary"
                    ],
                    6,
                    [
                        "tertiary"
                    ],
                    4,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    3,
                    3
                ],
                16,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway",
                        "trunk",
                        "primary"
                    ],
                    10,
                    [
                        "secondary"
                    ],
                    8,
                    [
                        "tertiary"
                    ],
                    8,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    4,
                    4
                ],
                20,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway",
                        "trunk",
                        "primary"
                    ],
                    26,
                    [
                        "secondary"
                    ],
                    26,
                    [
                        "tertiary"
                    ],
                    26,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    18,
                    18
                ]
            ],
            "line-dasharray": [
                0.5,
                0.25
            ]
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "brunnel",
                "tunnel"
            ],
            [
                "!in",
                "class",
                "bridge",
                "ferry",
                "rail",
                "transit",
                "pier",
                "path",
                "aerialway",
                "motorway_construction",
                "trunk_construction",
                "primary_construction",
                "secondary_construction",
                "tertiary_construction",
                "minor_construction",
                "service_construction",
                "track_construction"
            ]
        ]
    },
    {
        "id": "tunnel",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 4,
        "layout": {
            "line-cap": "butt",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": [
                "match",
                [
                    "get",
                    "class"
                ],
                "motorway",
                "hsl(35,100%,76%)",
                [
                    "trunk",
                    "primary"
                ],
                "hsl(48,100%,88%)",
                "hsl(0,0%,96%)"
            ],
            "line-width": [
                "interpolate",
                [
                    "linear",
                    2
                ],
                [
                    "zoom"
                ],
                5,
                0,
                6,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "brunnel"
                        ],
                        [
                            "bridge"
                        ],
                        0,
                        1
                    ],
                    [
                        "trunk",
                        "primary"
                    ],
                    0,
                    0
                ],
                10,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        0,
                        2.5
                    ],
                    [
                        "trunk",
                        "primary"
                    ],
                    1.5,
                    1
                ],
                12,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        1,
                        4
                    ],
                    [
                        "trunk"
                    ],
                    2.5,
                    [
                        "primary"
                    ],
                    2.5,
                    [
                        "secondary",
                        "tertiary"
                    ],
                    1.5,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    1,
                    1
                ],
                14,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        5,
                        6
                    ],
                    [
                        "trunk"
                    ],
                    3,
                    [
                        "primary"
                    ],
                    5,
                    [
                        "secondary"
                    ],
                    4,
                    [
                        "tertiary"
                    ],
                    3,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    2,
                    2
                ],
                16,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway",
                        "trunk",
                        "primary"
                    ],
                    8,
                    [
                        "secondary"
                    ],
                    7,
                    [
                        "tertiary"
                    ],
                    6,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    4,
                    4
                ],
                20,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway",
                        "trunk",
                        "primary"
                    ],
                    24,
                    [
                        "secondary"
                    ],
                    24,
                    [
                        "tertiary"
                    ],
                    24,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    16,
                    16
                ]
            ],
            "line-opacity": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "brunnel",
                "tunnel"
            ],
            [
                "!in",
                "class",
                "ferry",
                "rail",
                "transit",
                "pier",
                "bridge",
                "path",
                "aerialway",
                "motorway_construction",
                "trunk_construction",
                "primary_construction",
                "secondary_construction",
                "tertiary_construction",
                "minor_construction",
                "service_construction",
                "track_construction"
            ]
        ]
    },
    {
        "id": "tunnel_rail",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,73%)",
            "line-width": {
                "base": 1.4,
                "stops": [
                    [
                        14,
                        0.4
                    ],
                    [
                        15,
                        0.75
                    ],
                    [
                        20,
                        2
                    ]
                ]
            },
            "line-opacity": 0.5
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "brunnel",
                "tunnel"
            ],
            [
                "in",
                "class",
                "rail"
            ]
        ]
    },
    {
        "id": "tunnel_rail-hatching",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,73%)",
            "line-width": {
                "base": 1.4,
                "stops": [
                    [
                        14.5,
                        0
                    ],
                    [
                        15,
                        3
                    ],
                    [
                        20,
                        8
                    ]
                ]
            },
            "line-opacity": 0.5,
            "line-dasharray": [
                0.2,
                8
            ]
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "brunnel",
                "tunnel"
            ],
            [
                "==",
                "class",
                "rail"
            ]
        ]
    },
    {
        "id": "tunnel_footway-casing",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 12,
        "layout": {
            "line-cap": "round",
            "line-join": "miter",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,100%)",
            "line-width": {
                "base": 1.2,
                "stops": [
                    [
                        14,
                        0
                    ],
                    [
                        16,
                        0
                    ],
                    [
                        18,
                        4
                    ],
                    [
                        22,
                        8
                    ]
                ]
            },
            "line-opacity": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "in",
                "class",
                "path",
                "pedestrian"
            ],
            [
                "==",
                "brunnel",
                "tunnel"
            ]
        ]
    },
    {
        "id": "tunnel_footway",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 12,
        "layout": {
            "line-cap": "butt",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,63%)",
            "line-width": {
                "base": 1.2,
                "stops": [
                    [
                        14,
                        0.5
                    ],
                    [
                        16,
                        1
                    ],
                    [
                        18,
                        2
                    ],
                    [
                        22,
                        5
                    ]
                ]
            },
            "line-opacity": 0.4,
            "line-dasharray": {
                "stops": [
                    [
                        14,
                        [
                            1,
                            0.5
                        ]
                    ],
                    [
                        18,
                        [
                            1,
                            0.25
                        ]
                    ]
                ]
            }
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "in",
                "class",
                "path",
                "pedestrian"
            ],
            [
                "==",
                "brunnel",
                "tunnel"
            ]
        ]
    },
    {
        "id": "road_area_pier",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": "hsl(42,49%,93%)",
            "fill-antialias": true
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "Polygon"
            ],
            [
                "==",
                "class",
                "pier"
            ]
        ]
    },
    {
        "id": "road_pier",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(42,49%,93%)",
            "line-width": {
                "base": 1.2,
                "stops": [
                    [
                        15,
                        1
                    ],
                    [
                        17,
                        4
                    ]
                ]
            }
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "in",
                "class",
                "pier"
            ]
        ]
    },
    {
        "id": "road_area_bridge",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": "hsl(42,49%,93%)",
            "fill-opacity": 0.6,
            "fill-antialias": true
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "Polygon"
            ],
            [
                "==",
                "brunnel",
                "bridge"
            ]
        ]
    },
    {
        "id": "road_network-casing",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 4,
        "layout": {
            "line-cap": "butt",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": [
                "match",
                [
                    "get",
                    "class"
                ],
                "motorway",
                "hsl(28,72%,69%)",
                [
                    "trunk",
                    "primary"
                ],
                "hsl(28,72%,69%)",
                "hsl(36,5%,80%)"
            ],
            "line-width": [
                "interpolate",
                [
                    "linear",
                    2
                ],
                [
                    "zoom"
                ],
                6,
                0,
                7,
                0.5,
                10,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        0,
                        2.5
                    ],
                    [
                        "trunk",
                        "primary"
                    ],
                    2.4,
                    0
                ],
                12,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        2,
                        6
                    ],
                    [
                        "trunk",
                        "primary"
                    ],
                    3,
                    [
                        "secondary",
                        "tertiary"
                    ],
                    2,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    1,
                    0.5
                ],
                14,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        5,
                        8
                    ],
                    [
                        "trunk"
                    ],
                    4,
                    [
                        "primary"
                    ],
                    6,
                    [
                        "secondary"
                    ],
                    6,
                    [
                        "tertiary"
                    ],
                    4,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    3,
                    3
                ],
                16,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway",
                        "trunk",
                        "primary"
                    ],
                    10,
                    [
                        "secondary"
                    ],
                    8,
                    [
                        "tertiary"
                    ],
                    8,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    4,
                    4
                ],
                20,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway",
                        "trunk",
                        "primary"
                    ],
                    26,
                    [
                        "secondary"
                    ],
                    26,
                    [
                        "tertiary"
                    ],
                    26,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    18,
                    18
                ]
            ],
            "line-opacity": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "!in",
                "brunnel",
                "tunnel"
            ],
            [
                "!in",
                "class",
                "bridge",
                "ferry",
                "rail",
                "transit",
                "pier",
                "path",
                "aerialway",
                "motorway_construction",
                "trunk_construction",
                "primary_construction",
                "secondary_construction",
                "tertiary_construction",
                "minor_construction",
                "service_construction",
                "track_construction"
            ]
        ]
    },
    {
        "id": "road_network_construction",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 4,
        "layout": {
            "line-cap": "square",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": [
                "match",
                [
                    "get",
                    "class"
                ],
                "motorway_construction",
                "hsl(35,100%,76%)",
                [
                    "trunk_construction",
                    "primary_construction"
                ],
                "hsl(48,100%,83%)",
                "hsl(0,0%,100%)"
            ],
            "line-width": [
                "interpolate",
                [
                    "linear",
                    2
                ],
                [
                    "zoom"
                ],
                5,
                0,
                6,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway_construction"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "brunnel"
                        ],
                        [
                            "bridge"
                        ],
                        0,
                        1
                    ],
                    [
                        "trunk_construction",
                        "primary_construction"
                    ],
                    0,
                    0
                ],
                10,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway_construction"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        0,
                        2.5
                    ],
                    [
                        "trunk_construction",
                        "primary_construction"
                    ],
                    1.5,
                    1
                ],
                12,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway_construction"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        1,
                        4
                    ],
                    [
                        "trunk_construction"
                    ],
                    2.5,
                    [
                        "primary_construction"
                    ],
                    2.5,
                    [
                        "secondary_construction",
                        "tertiary_construction"
                    ],
                    1.5,
                    [
                        "minor_construction",
                        "service_construction",
                        "track_construction"
                    ],
                    1,
                    1
                ],
                14,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway_construction"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        5,
                        6
                    ],
                    [
                        "trunk_construction"
                    ],
                    3,
                    [
                        "primary_construction"
                    ],
                    5,
                    [
                        "secondary_construction"
                    ],
                    4,
                    [
                        "tertiary_construction"
                    ],
                    3,
                    [
                        "minor_construction",
                        "service_construction",
                        "track_construction"
                    ],
                    2,
                    2
                ],
                16,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway_construction",
                        "trunk_construction",
                        "primary_construction"
                    ],
                    8,
                    [
                        "secondary_construction"
                    ],
                    7,
                    [
                        "tertiary_construction"
                    ],
                    6,
                    [
                        "minor_construction",
                        "service_construction",
                        "track_construction"
                    ],
                    4,
                    4
                ],
                20,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway_construction",
                        "trunk_construction",
                        "primary_construction"
                    ],
                    24,
                    [
                        "secondary_construction"
                    ],
                    24,
                    [
                        "tertiary_construction"
                    ],
                    24,
                    [
                        "minor_construction",
                        "service_construction",
                        "track_construction"
                    ],
                    16,
                    16
                ]
            ],
            "line-opacity": [
                "case",
                [
                    "==",
                    [
                        "get",
                        "brunnel"
                    ],
                    "tunnel"
                ],
                0.7,
                1
            ],
            "line-dasharray": [
                2,
                2
            ]
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "class",
                "motorway_construction",
                "trunk_construction",
                "primary_construction",
                "secondary_construction",
                "tertiary_construction",
                "minor_construction",
                "service_construction",
                "track_construction"
            ]
        ]
    },
    {
        "id": "road_network",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 4,
        "layout": {
            "line-cap": "butt",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": [
                "match",
                [
                    "get",
                    "class"
                ],
                "motorway",
                "hsl(35,100%,76%)",
                [
                    "trunk",
                    "primary"
                ],
                "hsl(48,100%,83%)",
                "hsl(0,0%,100%)"
            ],
            "line-width": [
                "interpolate",
                [
                    "linear",
                    2
                ],
                [
                    "zoom"
                ],
                5,
                0.5,
                6,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "brunnel"
                        ],
                        [
                            "bridge"
                        ],
                        0,
                        1
                    ],
                    [
                        "trunk",
                        "primary"
                    ],
                    0,
                    0
                ],
                10,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        0,
                        2.5
                    ],
                    [
                        "trunk",
                        "primary"
                    ],
                    1.5,
                    1
                ],
                12,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        1,
                        4
                    ],
                    [
                        "trunk"
                    ],
                    2.5,
                    [
                        "primary"
                    ],
                    2.5,
                    [
                        "secondary",
                        "tertiary"
                    ],
                    1.5,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    1,
                    1
                ],
                14,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway"
                    ],
                    [
                        "match",
                        [
                            "get",
                            "ramp"
                        ],
                        1,
                        5,
                        6
                    ],
                    [
                        "trunk"
                    ],
                    3,
                    [
                        "primary"
                    ],
                    5,
                    [
                        "secondary"
                    ],
                    4,
                    [
                        "tertiary"
                    ],
                    3,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    2,
                    2
                ],
                16,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway",
                        "trunk",
                        "primary"
                    ],
                    8,
                    [
                        "secondary"
                    ],
                    7,
                    [
                        "tertiary"
                    ],
                    6,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    4,
                    4
                ],
                20,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "motorway",
                        "trunk",
                        "primary"
                    ],
                    24,
                    [
                        "secondary"
                    ],
                    24,
                    [
                        "tertiary"
                    ],
                    24,
                    [
                        "minor",
                        "service",
                        "track"
                    ],
                    16,
                    16
                ]
            ]
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "!=",
                "brunnel",
                "tunnel"
            ],
            [
                "!in",
                "class",
                "ferry",
                "rail",
                "transit",
                "pier",
                "bridge",
                "path",
                "aerialway",
                "motorway_construction",
                "trunk_construction",
                "primary_construction",
                "secondary_construction",
                "tertiary_construction",
                "minor_construction",
                "service_construction",
                "track_construction"
            ]
        ]
    },
    {
        "id": "road_path_pedestrian-casing",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 12,
        "layout": {
            "line-cap": "round",
            "line-join": "miter",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,100%)",
            "line-width": {
                "base": 1.2,
                "stops": [
                    [
                        14,
                        0
                    ],
                    [
                        16,
                        0
                    ],
                    [
                        18,
                        4
                    ],
                    [
                        22,
                        8
                    ]
                ]
            }
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "in",
                "class",
                "path",
                "pedestrian"
            ],
            [
                "!=",
                "brunnel",
                "tunnel"
            ]
        ]
    },
    {
        "id": "road_path_pedestrian",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 12,
        "layout": {
            "line-cap": "butt",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0, 0%, 79%)",
            "line-width": {
                "base": 1.2,
                "stops": [
                    [
                        14,
                        0.5
                    ],
                    [
                        16,
                        1
                    ],
                    [
                        18,
                        2
                    ],
                    [
                        22,
                        5
                    ]
                ]
            },
            "line-dasharray": {
                "stops": [
                    [
                        14,
                        [
                            1,
                            0.5
                        ]
                    ],
                    [
                        18,
                        [
                            1,
                            0.25
                        ]
                    ]
                ]
            }
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "in",
                "class",
                "path",
                "pedestrian"
            ],
            [
                "!=",
                "brunnel",
                "tunnel"
            ]
        ]
    },
    {
        "id": "rail_major",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "line-color": {
                "stops": [
                    [
                        8,
                        "hsl(0,0%,72%)"
                    ],
                    [
                        16,
                        "hsl(0,0%,70%)"
                    ]
                ]
            },
            "line-width": {
                "base": 1.4,
                "stops": [
                    [
                        14,
                        0.4
                    ],
                    [
                        15,
                        0.75
                    ],
                    [
                        20,
                        2
                    ]
                ]
            },
            "line-opacity": [
                "match",
                [
                    "get",
                    "service"
                ],
                "yard",
                0.5,
                1
            ]
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "!in",
                "brunnel",
                "tunnel"
            ],
            [
                "==",
                "class",
                "rail"
            ]
        ]
    },
    {
        "id": "rail_major-hatching",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,72%)",
            "line-width": {
                "base": 1.4,
                "stops": [
                    [
                        14.5,
                        0
                    ],
                    [
                        15,
                        3
                    ],
                    [
                        20,
                        8
                    ]
                ]
            },
            "line-opacity": [
                "match",
                [
                    "get",
                    "service"
                ],
                "yard",
                0.5,
                1
            ],
            "line-dasharray": [
                0.2,
                9
            ]
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "!in",
                "brunnel",
                "tunnel"
            ],
            [
                "==",
                "class",
                "rail"
            ]
        ]
    },
    {
        "id": "rail_minor",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,73%)",
            "line-width": {
                "base": 1.4,
                "stops": [
                    [
                        14,
                        0.4
                    ],
                    [
                        15,
                        0.75
                    ],
                    [
                        20,
                        2
                    ]
                ]
            }
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "subclass",
                "tram",
                "light_rail"
            ]
        ]
    },
    {
        "id": "rail_minor-hatching",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,73%)",
            "line-width": {
                "base": 1.4,
                "stops": [
                    [
                        14.5,
                        0
                    ],
                    [
                        15,
                        2
                    ],
                    [
                        20,
                        6
                    ]
                ]
            },
            "line-dasharray": [
                0.2,
                4
            ]
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "subclass",
                "tram",
                "light_rail"
            ]
        ]
    },
    {
        "id": "building",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "building",
        "minzoom": 13,
        "maxzoom": 15,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": "hsl(30,6%,73%)",
            "fill-opacity": 0.3,
            "fill-outline-color": {
                "base": 1,
                "stops": [
                    [
                        13,
                        "hsla(35, 6%, 79%, 0.3)"
                    ],
                    [
                        14,
                        "hsl(35, 6%, 79%)"
                    ]
                ]
            }
        },
        "metadata": {}
    },
    {
        "id": "building-3d",
        "type": "fill",
        "source": "maptiler_planet",
        "source-layer": "building",
        "minzoom": 15,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "fill-color": "#d6cfb5",
            "fill-opacity": 1
        },
        "metadata": {},
    },
    {
        "id": "building-line-3d",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "building",
        "minzoom": 15,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "line-color": "grey",
        },
        "metadata": {},
    },
    {
        "id": "aqueduct-casing",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "waterway",
        "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,51%)",
            "line-width": {
                "base": 1.3,
                "stops": [
                    [
                        14,
                        1
                    ],
                    [
                        20,
                        6
                    ]
                ]
            }
        },
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "==",
                "brunnel",
                "bridge"
            ]
        ]
    },
    {
        "id": "aqueduct",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "waterway",
        "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(204,92%,75%)",
            "line-width": {
                "base": 1.3,
                "stops": [
                    [
                        12,
                        0.5
                    ],
                    [
                        20,
                        5
                    ]
                ]
            }
        },
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "==",
                "brunnel",
                "bridge"
            ]
        ]
    },
    {
        "id": "cablecar",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 13,
        "layout": {
            "line-cap": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-blur": 1,
            "line-color": "hsl(0,0%,100%)",
            "line-width": {
                "base": 1,
                "stops": [
                    [
                        13,
                        2
                    ],
                    [
                        19,
                        4
                    ]
                ]
            }
        },
        "filter": [
            "==",
            "class",
            "aerialway"
        ]
    },
    {
        "id": "cablecar-dash",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "transportation",
        "minzoom": 13,
        "layout": {
            "line-cap": "round",
            "line-join": "bevel",
            "visibility": "visible"
        },
        "paint": {
            "line-color": "hsl(0,0%,64%)",
            "line-width": {
                "base": 1,
                "stops": [
                    [
                        13,
                        1
                    ],
                    [
                        19,
                        2
                    ]
                ]
            },
            "line-dasharray": [
                2,
                2
            ]
        },
        "filter": [
            "==",
            "class",
            "aerialway"
        ]
    },
    {
        "id": "border_other",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "boundary",
        "minzoom": 3,
        "maxzoom": 13,
        "layout": {
            "visibility": "visible"
        },
        "paint": {
            "line-color": [
                "match",
                [
                    "get",
                    "maritime"
                ],
                1,
                "hsla(210,52%,64%,0)",
                "hsla(0,0%,60%,0.5)"
            ],
            "line-width": {
                "stops": [
                    [
                        3,
                        0.75
                    ],
                    [
                        4,
                        1.25
                    ],
                    [
                        11,
                        1.75
                    ],
                    [
                        18,
                        3
                    ]
                ]
            },
            "line-dasharray": [
                2,
                1
            ]
        },
        "filter": [
            "all",
            [
                ">=",
                "admin_level",
                3
            ],
            [
                "==",
                "maritime",
                0
            ],
            [
                "<",
                "admin_level",
                10
            ]
        ]
    },
    {
        "id": "border_disputed",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "boundary",
        "minzoom": 0,
        "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-color": [
                "match",
                [
                    "get",
                    "maritime"
                ],
                1,
                "hsla(210,52%,64%,0)",
                "hsl(0,0%,63%)"
            ],
            "line-width": {
                "stops": [
                    [
                        1,
                        0.5
                    ],
                    [
                        5,
                        1.5
                    ],
                    [
                        10,
                        2
                    ]
                ]
            },
            "line-dasharray": [
                2,
                2
            ]
        },
        "filter": [
            "all",
            [
                "<=",
                "admin_level",
                2
            ],
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "==",
                "disputed",
                1
            ]
        ]
    },
    {
        "id": "border_country",
        "type": "line",
        "source": "maptiler_planet",
        "source-layer": "boundary",
        "minzoom": 0,
        "layout": {
            "line-cap": "round",
            "line-join": "round",
            "visibility": "visible"
        },
        "paint": {
            "line-blur": 0,
            "line-color": [
                "match",
                [
                    "get",
                    "maritime"
                ],
                1,
                "hsla(210,52%,64%,0)",
                "hsl(0,0%,54%)"
            ],
            "line-width": {
                "stops": [
                    [
                        1,
                        0.5
                    ],
                    [
                        5,
                        1.5
                    ],
                    [
                        10,
                        2
                    ]
                ]
            }
        },
        "filter": [
            "all",
            [
                "<=",
                "admin_level",
                2
            ],
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "==",
                "disputed",
                0
            ]
        ]
    },
    {
        "id": "waterway",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "waterway",
        "minzoom": 13,
        "layout": {
            "text-font": [
                "Inter Light",
                
            ],
            "text-size": {
                "stops": [
                    [
                        12,
                        8
                    ],
                    [
                        16,
                        14
                    ],
                    [
                        22,
                        20
                    ]
                ]
            },
            "text-field": "{name:latin}",
            "visibility": "visible",
            "symbol-spacing": 400,
            "text-max-width": 5,
            "symbol-placement": "line",
            "text-letter-spacing": 0.2,
            "text-rotation-alignment": "map"
        },
        "paint": {
            "text-color": "hsl(205,84%,39%)",
            "text-halo-blur": 1,
            "text-halo-color": "hsl(202, 76%, 82%)",
            "text-halo-width": {
                "stops": [
                    [
                        10,
                        1
                    ],
                    [
                        18,
                        2
                    ]
                ]
            }
        },
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ]
        ]
    },
    {
        "id": "water_point",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "water_name",
        "minzoom": 0,
        "layout": {
            "text-font": [
                "Inter Light",
                
            ],
            "text-size": [
                "interpolate",
                [
                    "linear",
                    1
                ],
                [
                    "zoom"
                ],
                1,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "ocean"
                    ],
                    14,
                    10
                ],
                3,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "ocean"
                    ],
                    18,
                    14
                ],
                9,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "ocean"
                    ],
                    22,
                    18
                ],
                14,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "lake"
                    ],
                    14,
                    [
                        "sea"
                    ],
                    20,
                    26
                ]
            ],
            "text-field": [
                "concat",
                [
                    "get",
                    "name:latin"
                ],
                "\n",
                [
                    "get",
                    "name:nonlatin"
                ]
            ],
            "visibility": "visible",
            "text-max-width": 5,
            "symbol-placement": "point"
        },
        "paint": {
            "text-color": {
                "stops": [
                    [
                        1,
                        "hsl(203,54%,54%)"
                    ],
                    [
                        4,
                        "hsl(203,72%,39%)"
                    ]
                ]
            },
            "text-opacity": [
                "step",
                [
                    "zoom"
                ],
                0,
                1,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "ocean"
                    ],
                    1,
                    0
                ],
                3,
                1
            ],
            "text-halo-blur": 1,
            "text-halo-color": {
                "stops": [
                    [
                        1,
                        "rgba(168, 221, 241, 0.05)"
                    ],
                    [
                        3,
                        "rgba(194, 235, 255, 0.75)"
                    ]
                ]
            },
            "text-halo-width": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "Point"
            ],
            [
                "has",
                "name"
            ],
            [
                "!=",
                "class",
                "lake"
            ]
        ]
    },
    {
        "id": "water_line",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "water_name",
        "minzoom": 0,
        "layout": {
            "text-font": [
                "Inter Light",
                
            ],
            "text-size": {
                "stops": [
                    [
                        10,
                        13
                    ],
                    [
                        14,
                        16
                    ],
                    [
                        22,
                        20
                    ]
                ]
            },
            "text-field": "{name:latin}",
            "visibility": "visible",
            "text-max-width": 5,
            "symbol-placement": "line",
            "text-letter-spacing": 0.1
        },
        "paint": {
            "text-color": "hsl(205,84%,39%)",
            "text-halo-color": "hsla(0, 100%, 100%, 0.45)",
            "text-halo-width": 1.5
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "$type",
                "LineString"
            ],
            [
                "==",
                "class",
                "lake"
            ]
        ]
    },
    {
        "id": "housenumber",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "housenumber",
        "minzoom": 18,
        "layout": {
            "text-font": [
                "Inter Regular",
                
            ],
            "text-size": 10,
            "text-field": "{housenumber}",
            "visibility": "visible"
        },
        "paint": {
            "text-color": "hsl(26,10%,44%)",
            "text-halo-blur": 1,
            "text-halo-color": "hsl(21,64%,96%)",
            "text-halo-width": 1
        }
    },
    {
        "id": "gondola",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "transportation_name",
        "minzoom": 13,
        "layout": {
            "text-font": [
                "Inter Light",
                
            ],
            "text-size": {
                "base": 1,
                "stops": [
                    [
                        13,
                        11
                    ],
                    [
                        15,
                        12
                    ],
                    [
                        18,
                        13
                    ],
                    [
                        22,
                        14
                    ]
                ]
            },
            "text-field": "{name:latin}",
            "visibility": "visible",
            "text-anchor": "center",
            "text-offset": [
                0.8,
                0.8
            ],
            "symbol-placement": "line"
        },
        "paint": {
            "text-color": "hsl(0,0%,40%)",
            "text-halo-blur": 1,
            "text-halo-color": "hsl(0,0%,100%)",
            "text-halo-width": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "subclass",
                "gondola",
                "cable_car"
            ]
        ]
    },
    {
        "id": "ferry",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "transportation_name",
        "minzoom": 12,
        "layout": {
            "text-font": [
                "Inter Light",
                
            ],
            "text-size": {
                "base": 1,
                "stops": [
                    [
                        13,
                        11
                    ],
                    [
                        15,
                        12
                    ]
                ]
            },
            "text-field": "{name:latin}",
            "visibility": "visible",
            "text-anchor": "center",
            "text-offset": [
                0.8,
                0.8
            ],
            "symbol-placement": "line"
        },
        "paint": {
            "text-color": "hsl(205,84%,39%)",
            "text-halo-blur": 0.5,
            "text-halo-color": "hsla(0, 0%, 100%, 0.15)",
            "text-halo-width": 1
        },
        "filter": [
            "all",
            [
                "==",
                "subclass",
                "ferry"
            ]
        ]
    },
    // {
    //     "id": "oneway",
    //     "type": "symbol",
    //     "source": "maptiler_planet",
    //     "source-layer": "transportation",
    //     "minzoom": 16,
    //     "layout": {
    //         "icon-size": {
    //             "stops": [
    //                 [
    //                     16,
    //                     0.7
    //                 ],
    //                 [
    //                     18,
    //                     1
    //                 ]
    //             ]
    //         },
    //         "text-font": [
    //             "Inter Regular",
    //             
    //         ],
    //         "icon-image": "oneway",
    //         "visibility": "visible",
    //         "icon-rotate": [
    //             "match",
    //             [
    //                 "get",
    //                 "oneway"
    //             ],
    //             1,
    //             90,
    //             -90
    //         ],
    //         "icon-padding": 2,
    //         "symbol-spacing": 75,
    //         "symbol-placement": "line",
    //         "icon-rotation-alignment": "map"
    //     },
    //     "paint": {
    //         "icon-opacity": 0.5
    //     },
    //     "filter": [
    //         "all",
    //         [
    //             "has",
    //             "oneway"
    //         ],
    //         [
    //             "in",
    //             "class",
    //             "motorway",
    //             "trunk",
    //             "primary",
    //             "secondary",
    //             "tertiary",
    //             "minor",
    //             "service"
    //         ]
    //     ]
    // },
    {
        "id": "road",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "transportation_name",
        "minzoom": 8,
        "layout": {
            "text-font": [
                "Inter Regular",
                
            ],
            "text-size": {
                "base": 1,
                "stops": [
                    [
                        13,
                        10
                    ],
                    [
                        14,
                        11
                    ],
                    [
                        18,
                        13
                    ],
                    [
                        22,
                        15
                    ]
                ]
            },
            "text-field": "{name:latin}",
            "visibility": "visible",
            "text-anchor": "center",
            "text-offset": [
                0,
                0.15
            ],
            "text-justify": "center",
            "text-optional": false,
            "text-max-width": 10,
            "symbol-placement": "line",
            "icon-keep-upright": false,
            "icon-allow-overlap": false,
            "text-allow-overlap": false,
            "icon-ignore-placement": false,
            "text-ignore-placement": false
        },
        "paint": {
            "text-color": "hsl(19, 0%, 16%)",
            "text-halo-blur": 0.5,
            "text-halo-color": "hsl(0,0%,100%)",
            "text-halo-width": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "!in",
                "subclass",
                "ferry",
                "gondola",
                "cable_car"
            ],
            [
                "!in",
                "class",
                "service"
            ]
        ]
    },
    // {
    //     "id": "highway-junction",
    //     "type": "symbol",
    //     "source": "maptiler_planet",
    //     "source-layer": "transportation_name",
    //     "minzoom": 16,
    //     "layout": {
    //         "icon-size": 1,
    //         "text-font": [
    //             "Inter Regular",
    //             
    //         ],
    //         "text-size": 9,
    //         "icon-image": "exit_{ref_length}",
    //         "text-field": "{ref}",
    //         "visibility": "visible",
    //         "text-offset": [
    //             0,
    //             0.1
    //         ],
    //         "symbol-spacing": 200,
    //         "symbol-z-order": "auto",
    //         "symbol-placement": "point",
    //         "symbol-avoid-edges": true,
    //         "icon-rotation-alignment": "viewport",
    //         "text-rotation-alignment": "viewport"
    //     },
    //     "paint": {
    //         "text-color": "hsl(0,0%,21%)",
    //         "text-halo-color": "hsl(0,0%,100%)",
    //         "text-halo-width": 1
    //     },
    //     "filter": [
    //         "all",
    //         [
    //             ">",
    //             "ref_length",
    //             0
    //         ],
    //         [
    //             "==",
    //             "$type",
    //             "Point"
    //         ],
    //         [
    //             "==",
    //             "subclass",
    //             "junction"
    //         ]
    //     ]
    // },
    // {
    //     "id": "highway-shield",
    //     "type": "symbol",
    //     "source": "maptiler_planet",
    //     "source-layer": "transportation_name",
    //     "minzoom": 8,
    //     "layout": {
    //         "icon-size": 1,
    //         "text-font": [
    //             "match",
    //             [
    //                 "get",
    //                 "class"
    //             ],
    //             "motorway",
    //             [
    //                 "literal",
    //                 [
    //                     "Roboto Bold",
    //                     "Noto Sans Bold"
    //                 ]
    //             ],
    //             [
    //                 "literal",
    //                 [
    //                     "Inter Regular"
    //                 ]
    //             ]
    //         ],
    //         "text-size": 10,
    //         "icon-image": "road_{ref_length}",
    //         "text-field": "{ref}",
    //         "visibility": "visible",
    //         "text-offset": [
    //             0,
    //             0.1
    //         ],
    //         "text-padding": 2,
    //         "symbol-spacing": {
    //             "stops": [
    //                 [
    //                     10,
    //                     200
    //                 ],
    //                 [
    //                     18,
    //                     400
    //                 ]
    //             ]
    //         },
    //         "text-transform": "uppercase",
    //         "symbol-placement": "line",
    //         "symbol-avoid-edges": true,
    //         "icon-rotation-alignment": "viewport",
    //         "text-rotation-alignment": "viewport"
    //     },
    //     "paint": {
    //         "text-color": "hsl(0,0%,18%)"
    //     },
    //     "filter": [
    //         "all",
    //         [
    //             "<=",
    //             "ref_length",
    //             6
    //         ],
    //         [
    //             "==",
    //             "$type",
    //             "LineString"
    //         ],
    //         [
    //             "!in",
    //             "network",
    //             "us-interstate",
    //             "us-highway",
    //             "us-state"
    //         ],
    //         [
    //             "!in",
    //             "class",
    //             "path"
    //         ]
    //     ]
    // },
    // {
    //     "id": "highway-shield-us",
    //     "type": "symbol",
    //     "source": "maptiler_planet",
    //     "source-layer": "transportation_name",
    //     "minzoom": 7,
    //     "layout": {
    //         "icon-size": 1.1,
    //         "text-font": [
    //             "match",
    //             [
    //                 "get",
    //                 "class"
    //             ],
    //             "motorway",
    //             [
    //                 "literal",
    //                 [
    //                     "Roboto Bold",
    //                     "Noto Sans Bold"
    //                 ]
    //             ],
    //             [
    //                 "literal",
    //                 [
    //                     "Inter Regular",
    //                     
    //                 ]
    //             ]
    //         ],
    //         "text-size": 9,
    //         "icon-image": "{network}_{ref_length}",
    //         "text-field": "{ref}",
    //         "visibility": "visible",
    //         "text-offset": [
    //             0,
    //             0.2
    //         ],
    //         "symbol-spacing": 200,
    //         "symbol-placement": {
    //             "base": 1,
    //             "stops": [
    //                 [
    //                     7,
    //                     "point"
    //                 ],
    //                 [
    //                     7,
    //                     "line"
    //                 ],
    //                 [
    //                     8,
    //                     "line"
    //                 ]
    //             ]
    //         },
    //         "symbol-avoid-edges": true,
    //         "icon-rotation-alignment": "viewport",
    //         "text-rotation-alignment": "viewport"
    //     },
    //     "paint": {
    //         "text-color": [
    //             "match",
    //             [
    //                 "get",
    //                 "network"
    //             ],
    //             "us-interstate",
    //             "hsl(0,0%,100%)",
    //             "hsl(0,0%,14%)"
    //         ]
    //     },
    //     "filter": [
    //         "all",
    //         [
    //             "<=",
    //             "ref_length",
    //             6
    //         ],
    //         [
    //             "==",
    //             "$type",
    //             "LineString"
    //         ],
    //         [
    //             "in",
    //             "network",
    //             "us-interstate",
    //             "us-highway",
    //             "us-state"
    //         ]
    //     ]
    // },
    // {
    //     "id": "parking",
    //     "type": "symbol",
    //     "source": "maptiler_planet",
    //     "source-layer": "poi",
    //     "minzoom": 17,
    //     "layout": {
    //         "icon-size": 0.7,
    //         "text-font": [
    //             "Inter Regular",
    //             
    //         ],
    //         "text-size": {
    //             "stops": [
    //                 [
    //                     12,
    //                     10
    //                 ],
    //                 [
    //                     16,
    //                     12
    //                 ],
    //                 [
    //                     22,
    //                     14
    //                 ]
    //             ]
    //         },
    //         "icon-image": "parking",
    //         "text-field": "",
    //         "visibility": "visible",
    //         "text-anchor": "top",
    //         "text-offset": [
    //             0,
    //             0.8
    //         ],
    //         "text-padding": 2,
    //         "text-max-width": 8,
    //         "icon-allow-overlap": false
    //     },
    //     "paint": {
    //         "icon-opacity": 1,
    //         "text-opacity": 1,
    //         "text-halo-blur": 0.5,
    //         "text-halo-color": "hsl(0,0%,100%)",
    //         "text-halo-width": 1
    //     },
    //     "metadata": {},
    //     "filter": [
    //         "all",
    //         [
    //             "==",
    //             "class",
    //             "parking"
    //         ],
    //         [
    //             "!has",
    //             "name"
    //         ]
    //     ]
    // },
    // {
    //     "id": "poi",
    //     "type": "symbol",
    //     "source": "maptiler_planet",
    //     "source-layer": "poi",
    //     "minzoom": 13,
    //     "layout": {
    //         "icon-size": 1,
    //         "text-font": [
    //             "Inter Regular",
    //             
    //         ],
    //         "text-size": {
    //             "stops": [
    //                 [
    //                     12,
    //                     10
    //                 ],
    //                 [
    //                     16,
    //                     12
    //                 ],
    //                 [
    //                     22,
    //                     14
    //                 ]
    //             ]
    //         },
    //         "icon-image": [
    //             "coalesce",
    //             [
    //                 "image",
    //                 [
    //                     "get",
    //                     "subclass"
    //                 ]
    //             ],
    //             [
    //                 "image",
    //                 [
    //                     "get",
    //                     "class"
    //                 ]
    //             ],
    //             [
    //                 "image",
    //                 "dot"
    //             ]
    //         ],
    //         "text-field": "{name:latin}",
    //         "visibility": "visible",
    //         "text-anchor": "top",
    //         "text-offset": [
    //             0,
    //             0.8
    //         ],
    //         "text-padding": 2,
    //         "text-max-width": 8,
    //         "icon-allow-overlap": false
    //     },
    //     "paint": {
    //         "text-color": [
    //             "match",
    //             [
    //                 "get",
    //                 "class"
    //             ],
    //             [
    //                 "aerialway",
    //                 "bus",
    //                 "bicycle_rental",
    //                 "entrance",
    //                 "ferry_terminal",
    //                 "harbor"
    //             ],
    //             "hsl(215,83%,53%)",
    //             [
    //                 "park",
    //                 "golf"
    //             ],
    //             "hsl(82,83%,25%)",
    //             [
    //                 "hospital"
    //             ],
    //             "hsl(6,94%,35%)",
    //             "hsl(17,17%,38%)"
    //         ],
    //         "icon-opacity": [
    //             "step",
    //             [
    //                 "zoom"
    //             ],
    //             0,
    //             15,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "class"
    //                 ],
    //                 [
    //                     "aerialway",
    //                     "castle",
    //                     "cemetery",
    //                     "diplomatic",
    //                     "ferry_terminal",
    //                     "golf",
    //                     "harbor",
    //                     "hospital",
    //                     "stadium",
    //                     "park",
    //                     "place_of_worship",
    //                     "zoo"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             16,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "class"
    //                 ],
    //                 [
    //                     "castle",
    //                     "cemetery",
    //                     "town_hall",
    //                     "diplomatic",
    //                     "golf",
    //                     "ferry_terminal",
    //                     "hospital",
    //                     "stadium",
    //                     "park",
    //                     "college",
    //                     "university",
    //                     "place_of_worship",
    //                     "zoo",
    //                     "museum",
    //                     "school",
    //                     "parking",
    //                     "lodging"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             17,
    //             1,
    //             22,
    //             1
    //         ],
    //         "text-opacity": [
    //             "step",
    //             [
    //                 "zoom"
    //             ],
    //             0,
    //             15,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "class"
    //                 ],
    //                 [
    //                     "castle",
    //                     "courthouse",
    //                     "diplomatic",
    //                     "golf",
    //                     "ferry_terminal",
    //                     "aerialway",
    //                     "harbor",
    //                     "stadium",
    //                     "park",
    //                     "university",
    //                     "hospital",
    //                     "place_of_worship",
    //                     "zoo"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             16,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "class"
    //                 ],
    //                 [
    //                     "castle",
    //                     "cemetery",
    //                     "town_hall",
    //                     "diplomatic",
    //                     "golf",
    //                     "harbor",
    //                     "college",
    //                     "university",
    //                     "ferry_terminal",
    //                     "hospital",
    //                     "stadium",
    //                     "park",
    //                     "place_of_worship",
    //                     "zoo",
    //                     "museum",
    //                     "school",
    //                     "lodging"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             17,
    //             1,
    //             22,
    //             1
    //         ],
    //         "text-halo-blur": 0.5,
    //         "text-halo-color": "hsl(0,0%,100%)",
    //         "text-halo-width": 1
    //     },
    //     "metadata": {},
    //     "filter": [
    //         "all",
    //         [
    //             "has",
    //             "name"
    //         ],
    //         [
    //             "!=",
    //             "class",
    //             "railway"
    //         ]
    //     ]
    // },
    {
        "id": "place",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "place",
        "minzoom": 4,
        "layout": {
            "icon-size": {
                "stops": [
                    [
                        6,
                        0.5
                    ],
                    [
                        8.9,
                        0.8
                    ],
                    [
                        9,
                        0
                    ]
                ]
            },
            "text-font": [
                "Inter Regular",
                
            ],
            "text-size": [
                "interpolate",
                [
                    "linear",
                    1
                ],
                [
                    "zoom"
                ],
                3,
                11,
                8,
                13,
                11,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    "village",
                    12,
                    [
                        "suburb",
                        "neighbourhood",
                        "quarter",
                        "hamlet",
                        "isolated_dwelling"
                    ],
                    9,
                    "island",
                    8,
                    12
                ],
                16,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    "village",
                    18,
                    [
                        "suburb",
                        "neighbourhood",
                        "quarter",
                        "hamlet",
                        "isolated_dwelling"
                    ],
                    15,
                    "island",
                    11,
                    16
                ]
            ],
            "text-field": "{name:latin}",
            "visibility": "visible",
            "text-anchor": "bottom",
            "text-offset": [
                0,
                0
            ],
            "text-padding": 2,
            "icon-optional": false,
            "text-max-width": [
                "match",
                [
                    "get",
                    "class"
                ],
                [
                    "island"
                ],
                6,
                8
            ],
            "text-transform": [
                "match",
                [
                    "get",
                    "class"
                ],
                [
                    "suburb",
                    "neighborhood",
                    "neighbourhood",
                    "quarter",
                    "island"
                ],
                "uppercase",
                "none"
            ],
            "icon-allow-overlap": true,
            "text-letter-spacing": [
                "match",
                [
                    "get",
                    "class"
                ],
                [
                    "suburb",
                    "neighborhood",
                    "neighbourhood",
                    "quarter",
                    "island"
                ],
                0.2,
                0
            ]
        },
        "paint": {
            "text-color": "hsl(0,0%,25%)",
            "icon-opacity": 1,
            "text-opacity": [
                "step",
                [
                    "zoom"
                ],
                1,
                8,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "island"
                    ],
                    0,
                    1
                ],
                9,
                [
                    "match",
                    [
                        "get",
                        "class"
                    ],
                    [
                        "island"
                    ],
                    1,
                    1
                ]
            ],
            "text-halo-color": "hsl(0,0%,100%)",
            "text-halo-width": 1.2
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "!in",
                "class",
                "continent",
                "country",
                "state",
                "region",
                "province",
                "city",
                "town"
            ]
        ]
    },
    // {
    //     "id": "station",
    //     "type": "symbol",
    //     "source": "maptiler_planet",
    //     "source-layer": "poi",
    //     "minzoom": 12,
    //     "layout": {
    //         "icon-size": {
    //             "stops": [
    //                 [
    //                     13,
    //                     0.8
    //                 ],
    //                 [
    //                     18,
    //                     1
    //                 ]
    //             ]
    //         },
    //         "text-font": [
    //             "Inter Medium",
    //             
    //         ],
    //         "text-size": {
    //             "stops": [
    //                 [
    //                     12,
    //                     10
    //                 ],
    //                 [
    //                     16,
    //                     12
    //                 ],
    //                 [
    //                     22,
    //                     15
    //                 ]
    //             ]
    //         },
    //         "icon-image": [
    //             "match",
    //             [
    //                 "get",
    //                 "subclass"
    //             ],
    //             [
    //                 "station"
    //             ],
    //             "railway",
    //             [
    //                 "subway",
    //                 "halt"
    //             ],
    //             "subway",
    //             [
    //                 "tram_stop"
    //             ],
    //             "tramway",
    //             ""
    //         ],
    //         "text-field": "{name:latin}",
    //         "visibility": "visible",
    //         "text-anchor": "top",
    //         "text-offset": [
    //             0,
    //             0.9
    //         ],
    //         "text-padding": 2,
    //         "text-max-width": 9,
    //         "text-line-height": 0.9
    //     },
    //     "paint": {
    //         "text-color": "hsl(215,83%,53%)",
    //         "icon-opacity": [
    //             "step",
    //             [
    //                 "zoom"
    //             ],
    //             0,
    //             12,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "subclass"
    //                 ],
    //                 [
    //                     "station"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             14,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "subclass"
    //                 ],
    //                 [
    //                     "station",
    //                     "subway"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             15,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "subclass"
    //                 ],
    //                 [
    //                     "station",
    //                     "halt",
    //                     "subway",
    //                     "tram_stop"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             17,
    //             1,
    //             22,
    //             1
    //         ],
    //         "text-opacity": [
    //             "step",
    //             [
    //                 "zoom"
    //             ],
    //             0,
    //             12,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "subclass"
    //                 ],
    //                 [
    //                     "station"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             14,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "subclass"
    //                 ],
    //                 [
    //                     "station",
    //                     "subway"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             15,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "subclass"
    //                 ],
    //                 [
    //                     "station",
    //                     "subway",
    //                     "halt",
    //                     "tram_stop"
    //                 ],
    //                 1,
    //                 0
    //             ],
    //             17,
    //             1,
    //             22,
    //             1
    //         ],
    //         "text-halo-blur": 0.5,
    //         "text-halo-color": "hsl(0,0%,100%)",
    //         "text-halo-width": 1
    //     },
    //     "metadata": {},
    //     "filter": [
    //         "all",
    //         [
    //             "==",
    //             "class",
    //             "railway"
    //         ],
    //         [
    //             "has",
    //             "name"
    //         ]
    //     ]
    // },
    // {
    //     "id": "airport",
    //     "type": "symbol",
    //     "source": "maptiler_planet",
    //     "source-layer": "aerodrome_label",
    //     "minzoom": 8,
    //     "layout": {
    //         "icon-size": [
    //             "interpolate",
    //             [
    //                 "linear"
    //             ],
    //             [
    //                 "zoom"
    //             ],
    //             8,
    //             0.6,
    //             10,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "class"
    //                 ],
    //                 "international",
    //                 0.8,
    //                 0.6
    //             ],
    //             16,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "class"
    //                 ],
    //                 "international",
    //                 1,
    //                 0.8
    //             ]
    //         ],
    //         "text-font": [
    //             "Inter Medium",
    //             
    //         ],
    //         "text-size": [
    //             "interpolate",
    //             [
    //                 "linear"
    //             ],
    //             [
    //                 "zoom"
    //             ],
    //             9,
    //             9,
    //             10,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "class"
    //                 ],
    //                 "international",
    //                 10,
    //                 7
    //             ],
    //             14,
    //             [
    //                 "match",
    //                 [
    //                     "get",
    //                     "class"
    //                 ],
    //                 "international",
    //                 13,
    //                 11
    //             ]
    //         ],
    //         "icon-image": [
    //             "match",
    //             [
    //                 "get",
    //                 "class"
    //             ],
    //             "international",
    //             "airport",
    //             "airfield"
    //         ],
    //         "text-field": {
    //             "stops": [
    //                 [
    //                     8,
    //                     " "
    //                 ],
    //                 [
    //                     9,
    //                     "{iata}"
    //                 ],
    //                 [
    //                     12,
    //                     "{name:latin}"
    //                 ]
    //             ]
    //         },
    //         "visibility": "visible",
    //         "text-anchor": "top",
    //         "text-offset": [
    //             0,
    //             0.8
    //         ],
    //         "text-padding": 2,
    //         "text-optional": true,
    //         "text-max-width": 9,
    //         "text-line-height": 1.4
    //     },
    //     "paint": {
    //         "text-color": "hsl(215,83%,53%)",
    //         "icon-opacity": 1,
    //         "text-halo-blur": 0.5,
    //         "text-halo-color": "hsl(0,0%,100%)",
    //         "text-halo-width": 1
    //     },
    //     "filter": [
    //         "all",
    //         [
    //             "has",
    //             "iata"
    //         ],
    //         [
    //             "!=",
    //             "class",
    //             "public"
    //         ]
    //     ]
    // },
    {
        "id": "airport_gate",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "aeroway",
        "minzoom": 15,
        "layout": {
            "text-font": [
                "Inter Medium",
                
            ],
            "text-size": {
                "stops": [
                    [
                        15,
                        10
                    ],
                    [
                        22,
                        18
                    ]
                ]
            },
            "text-field": "{ref}",
            "visibility": "visible"
        },
        "paint": {
            "text-color": "hsl(0,0%,40%)",
            "text-halo-blur": 0.5,
            "text-halo-color": "hsl(0,0%,100%)",
            "text-halo-width": 1
        },
        "filter": [
            "all",
            [
                "==",
                "class",
                "gate"
            ]
        ]
    },
    {
        "id": "state",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "place",
        "minzoom": 3,
        "maxzoom": 9,
        "layout": {
            "text-font": [
                "Inter Medium",
                
            ],
            "text-size": {
                "stops": [
                    [
                        3,
                        9
                    ],
                    [
                        5,
                        10
                    ],
                    [
                        6,
                        11
                    ]
                ]
            },
            "text-field": "{name:latin}",
            "visibility": "visible",
            "text-padding": 2,
            "text-max-width": 8,
            "text-transform": "uppercase",
            "text-letter-spacing": 0.1
        },
        "paint": {
            "text-color": "hsl(48,4%,44%)",
            "text-opacity": [
                "step",
                [
                    "zoom"
                ],
                0,
                3,
                [
                    "case",
                    [
                        "<=",
                        [
                            "get",
                            "rank"
                        ],
                        3
                    ],
                    1,
                    0
                ],
                8,
                [
                    "case",
                    [
                        "==",
                        [
                            "get",
                            "rank"
                        ],
                        0
                    ],
                    0,
                    1
                ]
            ],
            "text-halo-color": "hsla(0,0%,100%,0.75)",
            "text-halo-width": 0.8
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "in",
                "class",
                "state",
                "province"
            ],
            [
                "<=",
                "rank",
                6
            ]
        ]
    },
    // {
    //     "id": "town",
    //     "type": "symbol",
    //     "source": "maptiler_planet",
    //     "source-layer": "place",
    //     "minzoom": 4,
    //     "maxzoom": 16,
    //     "layout": {
    //         "icon-size": {
    //             "stops": [
    //                 [
    //                     6,
    //                     0.5
    //                 ],
    //                 [
    //                     14,
    //                     0.8
    //                 ]
    //             ]
    //         },
    //         "text-font": {
    //             "stops": [
    //                 [
    //                     6,
    //                     [
    //                         "Inter Regular",
    //                         
    //                     ]
    //                 ],
    //                 [
    //                     12,
    //                     [
    //                         "Inter Medium",
    //                         
    //                     ]
    //                 ]
    //             ]
    //         },
    //         "text-size": [
    //             "interpolate",
    //             [
    //                 "linear",
    //                 1
    //             ],
    //             [
    //                 "zoom"
    //             ],
    //             6,
    //             [
    //                 "case",
    //                 [
    //                     "<=",
    //                     [
    //                         "get",
    //                         "rank"
    //                     ],
    //                     12
    //                 ],
    //                 11,
    //                 10
    //             ],
    //             9,
    //             [
    //                 "case",
    //                 [
    //                     "<=",
    //                     [
    //                         "get",
    //                         "rank"
    //                     ],
    //                     15
    //                 ],
    //                 13,
    //                 12
    //             ],
    //             16,
    //             [
    //                 "case",
    //                 [
    //                     "<=",
    //                     [
    //                         "get",
    //                         "rank"
    //                     ],
    //                     15
    //                 ],
    //                 22,
    //                 20
    //             ]
    //         ],
    //         "icon-image": {
    //             "stops": [
    //                 [
    //                     6,
    //                     "circle"
    //                 ],
    //                 [
    //                     12,
    //                     " "
    //                 ]
    //             ]
    //         },
    //         "text-field": "{name:latin}",
    //         "visibility": "visible",
    //         "text-anchor": "bottom",
    //         "text-offset": [
    //             0,
    //             0
    //         ],
    //         "icon-optional": false,
    //         "text-max-width": 8,
    //         "icon-allow-overlap": true
    //     },
    //     "paint": {
    //         "text-color": "hsl(0,0%,20%)",
    //         "text-halo-blur": 0.5,
    //         "text-halo-color": "hsl(0,0%,100%)",
    //         "text-halo-width": 1
    //     },
    //     "metadata": {},
    //     "filter": [
    //         "all",
    //         [
    //             "==",
    //             "class",
    //             "town"
    //         ]
    //     ]
    // },
    {
        "id": "city",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "place",
        "minzoom": 4,
        "maxzoom": 16,
        "layout": {
            "icon-size": [
                "interpolate",
                [
                    "linear",
                    1
                ],
                [
                    "zoom"
                ],
                4,
                [
                    "case",
                    [
                        "==",
                        [
                            "get",
                            "capital"
                        ],
                        2
                    ],
                    1,
                    0.8
                ],
                8,
                [
                    "case",
                    [
                        "==",
                        [
                            "get",
                            "capital"
                        ],
                        2
                    ],
                    1,
                    0.8
                ],
                12.9,
                [
                    "case",
                    [
                        "==",
                        [
                            "get",
                            "capital"
                        ],
                        2
                    ],
                    1.2,
                    1
                ],
                13,
                0
            ],
            "text-font": [
                "Inter Medium",
                
            ],
            "text-size": [
                "interpolate",
                [
                    "linear",
                    1
                ],
                [
                    "zoom"
                ],
                4,
                [
                    "case",
                    [
                        "<=",
                        [
                            "get",
                            "rank"
                        ],
                        2
                    ],
                    14,
                    12
                ],
                8,
                [
                    "case",
                    [
                        "<=",
                        [
                            "get",
                            "rank"
                        ],
                        4
                    ],
                    18,
                    14
                ],
                12,
                [
                    "case",
                    [
                        "<=",
                        [
                            "get",
                            "rank"
                        ],
                        4
                    ],
                    24,
                    18
                ],
                16,
                [
                    "case",
                    [
                        "<=",
                        [
                            "get",
                            "rank"
                        ],
                        4
                    ],
                    32,
                    26
                ]
            ],
            "icon-image": [
                "step",
                [
                    "zoom"
                ],
                "circle-stroke",
                13,
                ""
            ],
            "text-field": "{name:latin}",
            "visibility": "visible",
            "text-anchor": "bottom",
            "text-offset": [
                0,
                0
            ],
            "icon-optional": false,
            "text-max-width": 8,
            "icon-allow-overlap": true
        },
        "paint": {
            "text-color": "hsl(0,0%,20%)",
            "text-halo-blur": 0.5,
            "text-halo-color": "hsl(0,0%,100%)",
            "text-halo-width": 0.8
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "class",
                "city"
            ]
        ]
    },
    {
        "id": "country",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "place",
        "minzoom": 1,
        "maxzoom": 12,
        "layout": {
            "text-font": [
                "Inter Medium",
                
            ],
            "text-size": [
                "interpolate",
                [
                    "linear",
                    1
                ],
                [
                    "zoom"
                ],
                0,
                8,
                1,
                10,
                4,
                [
                    "case",
                    [
                        ">",
                        [
                            "get",
                            "rank"
                        ],
                        2
                    ],
                    15,
                    17
                ],
                8,
                [
                    "case",
                    [
                        ">",
                        [
                            "get",
                            "rank"
                        ],
                        2
                    ],
                    19,
                    23
                ]
            ],
            "text-field": "{name:latin}",
            "visibility": "visible",
            "text-padding": 1,
            "text-max-width": {
                "stops": [
                    [
                        1,
                        5
                    ],
                    [
                        5,
                        8
                    ]
                ]
            },
            "text-transform": "none",
            "text-allow-overlap": false,
            "text-letter-spacing": 0.07
        },
        "paint": {
            "text-color": "hsl(0, 0%, 20%)",
            "text-opacity": [
                "interpolate",
                [
                    "linear",
                    1
                ],
                [
                    "zoom"
                ],
                4,
                [
                    "case",
                    [
                        ">",
                        [
                            "get",
                            "rank"
                        ],
                        4
                    ],
                    0,
                    1
                ],
                5.9,
                [
                    "case",
                    [
                        ">",
                        [
                            "get",
                            "rank"
                        ],
                        4
                    ],
                    0,
                    1
                ],
                6,
                [
                    "case",
                    [
                        ">",
                        [
                            "get",
                            "rank"
                        ],
                        4
                    ],
                    1,
                    1
                ]
            ],
            "text-halo-blur": 0.8,
            "text-halo-color": "hsl(0,0%,100%)",
            "text-halo-width": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "class",
                "country"
            ],
            [
                "has",
                "iso_a2"
            ]
        ]
    },
    {
        "id": "continent",
        "type": "symbol",
        "source": "maptiler_planet",
        "source-layer": "place",
        "maxzoom": 1,
        "layout": {
            "text-font": [
                "Inter Medium",
                
            ],
            "text-size": {
                "stops": [
                    [
                        0,
                        12
                    ],
                    [
                        2,
                        13
                    ]
                ]
            },
            "text-field": "{name:latin}",
            "visibility": "visible",
            "text-justify": "center",
            "text-transform": "uppercase"
        },
        "paint": {
            "text-color": "hsl(0,0%,19%)",
            "text-halo-blur": 1,
            "text-halo-color": "hsl(0,0%,100%)",
            "text-halo-width": 1
        },
        "metadata": {},
        "filter": [
            "all",
            [
                "==",
                "class",
                "continent"
            ]
        ]
    }
]



export function getOsmWithMaxZoom(maxZoom: number) {
    return OSM_DEFAULT_LAYERS.map(layer => { 
        if (layer.maxzoom > 16 || layer.maxzoom === undefined) {
            return {
                ...layer,
                maxzoom: maxZoom
            }
        };

        return layer;
    })
}