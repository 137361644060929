import { LayerSpecification } from 'maplibre-gl';
import { BGT_LAYERS } from 'src/app/shared/map/bgt-style';
import { getOsmWithMaxZoom } from 'src/app/shared/map/osm-style';

export const BASEMAP_LAYERS: LayerSpecification[] = [
    {
        id: 'brt',
        type: 'raster',
        paint: {
            'raster-opacity': 1,
        },
        layout: {
            visibility: 'none',
        },
        source: 'brt'
    },
    {
        id: 'luchtfoto',
        type: 'raster',
        paint: {
            'raster-opacity': 1,
        },
        layout: {
            visibility: 'none',
        },
        source: 'luchtfoto'
    },
    {
        id: 'brk',
        type: 'raster',
        paint: {
            'raster-opacity': 1,
        },
        layout: {
            visibility: 'none',
        },
        source: 'brk'
    },

    {
        id: 'ahn',
        type: 'raster',
        paint: {
            'raster-opacity': 1,
        },
        layout: {
            visibility: 'none',
        },
        source: 'ahn'
    },
    {
        id: 'none',
        type: 'fill',
        paint: {
        },
        layout: {
            visibility: 'none',
        },
        source: 'none'
    },

];

export const BACKGROUND_LAYERS = [
    ...getOsmWithMaxZoom(17),
    ...BGT_LAYERS
  ];
  