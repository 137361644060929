import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout } from 'src/app/core/states/models/auth.state.model';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent {
  constructor(
    private store: Store
  ) {
    this.store.dispatch(new Logout());
  }
}
