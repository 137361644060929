import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpinService } from 'src/app/core/services/spin.service';
import { finalize } from 'rxjs/operators';
import { CoreModule } from '../core.module';


@Injectable({
    providedIn: CoreModule
})
export class RequestCountInterceptor implements HttpInterceptor {
    constructor(private spinner: SpinService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.incrementCount();
        return next.handle(request).pipe(
            finalize(() => this.spinner.decrementCount())
        );
    }
}
