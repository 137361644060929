import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CoreModule } from '../core.module';
import { SEO, SUPPORT } from '../constants';
import { FAQService } from './faq.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: CoreModule
})
export class SeoService {
  public structuredData: BehaviorSubject<any> = new BehaviorSubject([]);
  private organisationStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Mister KLIC',
    legalName: 'Mister KLIC',
    url: 'https://misterklic.nl',
    image: "https://misterklic.nl/favicon.ico",
    logo: "https://misterklic.nl/favicon.ico",
    description: SEO.DEFAULT_DESCRIPTION,
    foundingDate: 2016,
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Nieuwegracht 2b',
      addressLocality: 'Utrecht',
      addressRegion: 'LP',
      postalCode: 3512,
      addressCountry: {
        '@type': 'Country',
        name: 'Nederland'
      }
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      email: SUPPORT
    }
  };
  public websiteStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://misterKLIC.nl/',
    author: this.organisationStructuredData,
    "image": {
      "@type": "ImageObject",
      "url": "https://misterklic.nl/favicon.ico",
      "width": "48",
      "height": "48"
    }
  };
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private faqService: FAQService,
    @Inject(DOCUMENT) private document: Document
  ) { }
  public addSeoData(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      let root = this.router.routerState.snapshot.root;
      // Always set default description, but override it if another description exists.
      this.setDefaults();
      while (root) {
        if (root.children && root.children.length) {
          root = root.children[0];
        } else if (root.data && root.data['getTitle']) {
          // dynamic
          this.setDynamicTags(root);
          this.setStructuredData(root);
          return;
        } else if (root.data && root.data['title']) {
          // static
          this.setTags(root);
          this.setStructuredData(root);
          return;
        } else {
          return;
        }
      }
    });
  }
  public async setStructuredData(root: ActivatedRouteSnapshot) {
    const structuredData: any[] = [];
    const structuredDataObject = root.data['structuredData'];
    if (structuredDataObject) {
      // NOTE: Once we have more landing/sales pages, 
      // we need to put structured data on different pages instead of everything on the landing.
      if (structuredDataObject['@type'] === 'FAQPage') {
        const faqItems = this.faqService.faqContentItems.map(faq => this.setFaqData(faq));
        const flatFaqItems = faqItems.flat();
        const faqPageObject = {
          '@context': 'https://schema.org',
          ...structuredDataObject,
          mainEntity: flatFaqItems,
          ...this.setBasicData()
        };
        structuredData.push(faqPageObject, this.websiteStructuredData, this.organisationStructuredData);
      }
    }
    this.structuredData.next(structuredData);
  }
  private setBasicData() {
    return {
      name: this.titleService.getTitle(),
      description: this.metaService.getTag('name=description')!.content,
      url: `${document.location.origin}${this.router.url}`
    };
  }
  private setFaqData(faqQuestion: any) {
    return {
      '@type': 'Question',
      name: faqQuestion.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faqQuestion.answer
      }
    };
  }

  private setDefaults() {
    this.setTitle(SEO.DEFAULT_TITLE);
    this.updateTag('description', SEO.DEFAULT_DESCRIPTION);
  }

  public setDynamicTags(root: ActivatedRouteSnapshot) {
    this.setTitle(root.data['getTitle'](root.data, root.params));
    const tags = root.data['getMetatags'];

    this.removeImageTag();
    this.setImageTag(SEO.DEFAULT_IMAGE);

    if (tags) {
      Object.entries(tags).forEach(([tag, getValue]: [string, any]) => {
        this.updateTag(tag, getValue(root.data, root.params));
      });
    }
  }

  public setTags(root: ActivatedRouteSnapshot) {
    this.setTitle(root.data['title']);
    const tags = root.data['metatags'];

    this.removeImageTag();
    this.setImageTag(SEO.DEFAULT_IMAGE);

    if (tags) {
      Object.entries(tags).forEach(([tag, value]: [string, any]) => {
        this.updateTag(tag, value);
      });
    }
  }
  public updateTag(tagName: string, content: string) {
    if (tagName === 'image') { 
      this.removeImageTag();
      this.setImageTag(content);
    } else {
      this.metaService.removeTag(`name='${tagName}'`);
      this.metaService.addTag({ name: tagName, content: content });
    }

  }
  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }
  
  private setImageTag(url: string) {
    let link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'image_src');
    link.setAttribute('href', url);
    this.document.head.appendChild(link);
    this.metaService.addTag({ property: `og:image`, content: url })
  }

  private removeImageTag() {
    const existingElement = this.document.head.querySelector(`link[rel="image_src"]`);
    if (existingElement) {
      this.document.head.removeChild(existingElement);
    }
    this.metaService.removeTag(`property='og:image'`);
  }

}