import { Role, User } from "../../interfaces/user.interface";

export interface UsersStateModel {
  users: {
    [key: string]: User;
  },
  selectedUserId: string | null;
}

export interface UpdateUserParams {
  email: string, firstName: string, lastName: string, organisationId: string,  password?: string, roles: Role[], phoneNumber?: string
}

export class ListUsers {
  static readonly type = '[Users] List';
}

export class GetUserById {
  static readonly type = '[Users] GetById';
  constructor(public payload: {userId: string}) {}
}

export class ListUsersInOrganisation {
  static readonly type = '[Users] ListInOrganisation';
  constructor(public payload: {organisationId: string}) {}
}

export class DeleteUser {
  static readonly type = '[Users] Delete';
  constructor(public payload: {userId: string}) {}
}

export class CreateUser {
  static readonly type = '[Users] Create';
  constructor(public payload: { user: UpdateUserParams}) {}
}

export class UpdateUser {
  static readonly type = '[Users] Update';
  constructor(public payload: {userId: string,  user: Partial<UpdateUserParams>}) {}
}


