import { MrKLICSubscription, SubscriptionType } from "../../interfaces/subscription.interface"

export interface SubscriptionTypeStateModel {
    subscriptionTypes: {
        [key: string]: SubscriptionType
    },
}

export class ListSubscriptionTypes {
    static readonly type = '[SubscriptionTypes] List'
    constructor() { }
}