import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError, mergeMap } from 'rxjs/operators';
import { CoreModule } from '../core.module';
import { from, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '../states/auth.state';
import { Router } from '@angular/router';

@Injectable({
  providedIn: CoreModule
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return from(this.interceptRequest(request)).pipe(
      mergeMap(request => next.handle(request as any))
    ).pipe(
      catchError((err) => {
        const notLoginRoute = err.url?.includes('login') === false;
        if (err instanceof HttpErrorResponse && err.statusText === 'Unauthorized' && notLoginRoute) {
          this.router.navigate(['logout']);
        }
        return throwError(err);
      })
    );
  }
  private async interceptRequest(request: HttpRequest<any>) {
    const loggedIn = this.store.selectSnapshot(AuthState.isAuthenticated);
    const isApiRequest = request.url.includes(environment.apiBaseUrl + '/api/v1');

    if (loggedIn && isApiRequest) {
      // Check if the current access token is expired. If it is , we need to get a refreshToken.
      // If the refresh token is expired, an error will occur and the user will be logged out.
      let token = this.store.selectSnapshot(AuthState.token)!;

      return request.clone({
        headers: request.headers.append('Authorization', `Bearer ${token}`),
      });
    } else {
      return request;
    }

  }
}
