export const BGT_SPRITESHEET = "https://api.pdok.nl/lv/bgt/ogc/v1_0/resources/standaardsprites/sprites";
export const BGT_GLYPHS = "https://api.pdok.nl/lv/bgt/ogc/v1_0/resources/fonts/{fontstack}/{range}.pbf";
export const BGT_LAYERS = [
    {
        "filter": [
            "all",
            ["!=", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "water ondersteunendwaterdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#9BCBE9", "fill-outline-color": "#9BCBE9" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "water",
            "sourcelayer": "ondersteunendwaterdeel",
            "sld": "sld\\achtergrondvisualisatie\\2_achtergrond_ondersteunendwaterdeel_polygon.sld"
        },
        "source-layer": "ondersteunendwaterdeel"
    },
    {
        "filter": [
            "all",
            ["==", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Water_edge ondersteunendwaterdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "Water_edge",
            "sourcelayer": "ondersteunendwaterdeel",
            "sld": "sld\\achtergrondvisualisatie\\2_achtergrond_ondersteunendwaterdeel_polygon.sld"
        },
        "source-layer": "ondersteunendwaterdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "water waterdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#9BCBE9", "fill-outline-color": "#9BCBE9" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "water",
            "sourcelayer": "waterdeel",
            "sld": "sld\\achtergrondvisualisatie\\3_achtergrond_waterdeel_polygon.sld"
        },
        "source-layer": "waterdeel"
    },
    {
        "filter": [
            "all",
            ["==", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Water_edge waterdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "Water_edge",
            "sourcelayer": "waterdeel",
            "sld": "sld\\achtergrondvisualisatie\\3_achtergrond_waterdeel_polygon.sld"
        },
        "source-layer": "waterdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "onverhard"],
                ["==", "fysiek_voorkomen", "gesloten verharding"],
                ["==", "fysiek_voorkomen", "open verharding"],
                ["==", "fysiek_voorkomen", "half verhard"],
                ["==", "fysiek_voorkomen", "fruitteelt"],
                ["==", "fysiek_voorkomen", "bouwland"],
                ["==", "fysiek_voorkomen", "grasland agrarisch"],
                ["==", "fysiek_voorkomen", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-human-made onbegroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#fefefe", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-human-made",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "groenvoorziening"],
                ["==", "fysiek_voorkomen", "struiken"],
                ["==", "fysiek_voorkomen", "houtwal"],
                ["==", "fysiek_voorkomen", "grasland overig"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-low-vegetation onbegroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-low-vegetation",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "loofbos"],
                ["==", "fysiek_voorkomen", "gemengd bos"],
                ["==", "fysiek_voorkomen", "naaldbos"],
                ["==", "fysiek_voorkomen", "boomteelt"],
                ["==", "plus_fysiek_voorkomen", "bosplantsoen"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-high-vegetation onbegroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#c3dbb6", "fill-outline-color": "#c3dbb6" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-high-vegetation",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "duin"],
                ["==", "fysiek_voorkomen", "moeras"],
                ["==", "fysiek_voorkomen", "rietland"],
                ["==", "fysiek_voorkomen", "kwelder"],
                ["==", "fysiek_voorkomen", "zand"],
                ["==", "plus_fysiek_voorkomen", "zand"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "landuse-natural-sand onbegroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "landuse-natural-sand",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "heide"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-heather onbegroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#e3dce7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-heather",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z23 onbegroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#f9f9e7", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z23",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z1 onbegroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#F9F9E7", "fill-outline-color": "#F9F9E7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z1",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "onverhard"],
                ["==", "fysiek_voorkomen", "gesloten verharding"],
                ["==", "fysiek_voorkomen", "open verharding"],
                ["==", "fysiek_voorkomen", "half verhard"],
                ["==", "fysiek_voorkomen", "fruitteelt"],
                ["==", "fysiek_voorkomen", "bouwland"],
                ["==", "fysiek_voorkomen", "grasland agrarisch"],
                ["==", "fysiek_voorkomen", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-human-made begroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#fefefe", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-human-made",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "groenvoorziening"],
                ["==", "fysiek_voorkomen", "struiken"],
                ["==", "fysiek_voorkomen", "houtwal"],
                ["==", "fysiek_voorkomen", "grasland overig"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-low-vegetation begroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-low-vegetation",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "loofbos"],
                ["==", "fysiek_voorkomen", "gemengd bos"],
                ["==", "fysiek_voorkomen", "naaldbos"],
                ["==", "fysiek_voorkomen", "boomteelt"],
                ["==", "plus_fysiek_voorkomen", "bosplantsoen"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-high-vegetation begroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#c3dbb6", "fill-outline-color": "#c3dbb6" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-high-vegetation",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "duin"],
                ["==", "fysiek_voorkomen", "moeras"],
                ["==", "fysiek_voorkomen", "rietland"],
                ["==", "fysiek_voorkomen", "kwelder"],
                ["==", "fysiek_voorkomen", "zand"],
                ["==", "plus_fysiek_voorkomen", "zand"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "landuse-natural-sand begroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "landuse-natural-sand",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "heide"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-heather begroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#e3dce7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-heather",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z23 begroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#f9f9e7", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z23",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z1 begroeidterreindeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#F9F9E7", "fill-outline-color": "#F9F9E7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z1",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "id": "Tunnels en Overbrugging tunneldeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieinfra_misc",
            "id": "Tunnels en Overbrugging",
            "sourcelayer": "tunneldeel",
            "sld": "sld\\achtergrondvisualisatie\\6_achtergrond_tunneldeel_infra1_extra_polygon.sld"
        },
        "source-layer": "tunneldeel",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "id": "Tunnels en Overbrugging overbruggingsdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieinfra_misc",
            "id": "Tunnels en Overbrugging",
            "sourcelayer": "overbruggingsdeel",
            "sld": "sld\\achtergrondvisualisatie\\7_achtergrond_overbruggingsdeel_infra2_extra_polygon.sld"
        },
        "source-layer": "overbruggingsdeel",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "filter": [
            "all",
            ["==", "functie", "verkeerseiland"],
            ["!=", "fysiek_voorkomen", "groenvoorziening"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Verkeerseiland ondersteunendwegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#fdeff8", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieverkeerseiland",
            "id": "Verkeerseiland",
            "sourcelayer": "ondersteunendwegdeel",
            "sld": "sld\\achtergrondvisualisatie\\8_achtergrond_ondersteunendwegdeel_infra_side.sld"
        },
        "source-layer": "ondersteunendwegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "groenvoorziening"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Groenvoorziening ondersteunendwegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieverkeerseiland",
            "id": "Groenvoorziening",
            "sourcelayer": "ondersteunendwegdeel",
            "sld": "sld\\achtergrondvisualisatie\\8_achtergrond_ondersteunendwegdeel_infra_side.sld"
        },
        "source-layer": "ondersteunendwegdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "fysiek_voorkomen", "groenvoorziening"],
            ["!=", "functie", "verkeerseiland"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Overig wit ondersteunendwegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieverkeerseiland",
            "id": "Overig wit",
            "sourcelayer": "ondersteunendwegdeel",
            "sld": "sld\\achtergrondvisualisatie\\8_achtergrond_ondersteunendwegdeel_infra_side.sld"
        },
        "source-layer": "ondersteunendwegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic casing wegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic casing",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic Yellow fill wegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic Yellow fill",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic White fill wegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic White fill",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking casing wegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking casing",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking fill wegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking fill",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic casing wegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic casing",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic fill  wegdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#fdeff8", "fill-outline-color": "#fdeff8" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic fill ",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram z12 wegdeel line0",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.8 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram z12",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram_z1  wegdeel line0",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram_z1 ",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z23 wegdeel line0",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z23 wegdeel line0",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z1 wegdeel line0",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 3 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z1 wegdeel line0",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z23 wegdeel line0",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z23 wegdeel line0",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2,
            "line-dasharray": [11, 9]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z1 wegdeel line0",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z1 wegdeel line0",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [13, 11]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic casing spoor fill0",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic casing",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic Yellow fill spoor fill0",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic Yellow fill",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic White fill spoor fill0",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic White fill",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking casing spoor fill0",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking casing",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking fill spoor fill0",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking fill",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic casing spoor fill0",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic casing",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic fill  spoor fill0",
        "type": "fill",
        "paint": { "fill-color": "#fdeff8", "fill-outline-color": "#fdeff8" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic fill ",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram z12 spoor line0",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.8 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram z12",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram_z1  spoor line0",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram_z1 ",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z23 spoor line0",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z23 spoor line0",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z1 spoor line0",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 3 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z1 spoor line0",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z23 spoor line0",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z23 spoor line0",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2,
            "line-dasharray": [11, 9]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z1 spoor line0",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z1 spoor line0",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [13, 11]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "id": "urban_casing_z1 pand fill0",
        "type": "fill",
        "paint": { "fill-opacity": 0, "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_casing_z1",
            "sourcelayer": "pand",
            "sld": "sld\\achtergrondvisualisatie\\11_achtergrond_pand_urban_polygon.sld"
        },
        "source-layer": "pand",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "id": "urban_fill_z1 pand fill0",
        "type": "fill",
        "paint": { "fill-color": "#d3d3d3", "fill-outline-color": "#cccccc" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_fill_z1",
            "sourcelayer": "pand",
            "sld": "sld\\achtergrondvisualisatie\\11_achtergrond_pand_urban_polygon.sld"
        },
        "source-layer": "pand",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "id": "urban_z23 pand fill0",
        "type": "fill",
        "paint": { "fill-color": "#d3d3d3", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_z23",
            "sourcelayer": "pand",
            "sld": "sld\\achtergrondvisualisatie\\11_achtergrond_pand_urban_polygon.sld"
        },
        "source-layer": "pand",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z23 gebouwinstallatie fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z23",
            "sourcelayer": "gebouwinstallatie",
            "sld": "sld\\achtergrondvisualisatie\\12_achtergrond_gebouwinstallatie_urban_extra_polygon.sld"
        },
        "source-layer": "gebouwinstallatie"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z1 gebouwinstallatie fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z1",
            "sourcelayer": "gebouwinstallatie",
            "sld": "sld\\achtergrondvisualisatie\\12_achtergrond_gebouwinstallatie_urban_extra_polygon.sld"
        },
        "source-layer": "gebouwinstallatie"
    },
    {
        "filter": [
            "all",
            ["==", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "overkapping gebouwinstallatie fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "overkapping",
            "sourcelayer": "gebouwinstallatie",
            "sld": "sld\\achtergrondvisualisatie\\12_achtergrond_gebouwinstallatie_urban_extra_polygon.sld"
        },
        "source-layer": "gebouwinstallatie"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z23 overigbouwwerk fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z23",
            "sourcelayer": "overigbouwwerk",
            "sld": "sld\\achtergrondvisualisatie\\13_achtergrond_overigbouwwerk_urban_extra_polygon_kopie.sld"
        },
        "source-layer": "overigbouwwerk"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z1 overigbouwwerk fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z1",
            "sourcelayer": "overigbouwwerk",
            "sld": "sld\\achtergrondvisualisatie\\13_achtergrond_overigbouwwerk_urban_extra_polygon_kopie.sld"
        },
        "source-layer": "overigbouwwerk"
    },
    {
        "filter": [
            "all",
            ["==", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "overkapping overigbouwwerk fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "overkapping",
            "sourcelayer": "overigbouwwerk",
            "sld": "sld\\achtergrondvisualisatie\\13_achtergrond_overigbouwwerk_urban_extra_polygon_kopie.sld"
        },
        "source-layer": "overigbouwwerk"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z23 kunstwerkdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z23",
            "sourcelayer": "kunstwerkdeel",
            "sld": "sld\\achtergrondvisualisatie\\14_achtergrond_kunstwerkdeel_urban_extra_polygon_ kopie.sld"
        },
        "source-layer": "kunstwerkdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z1 kunstwerkdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z1",
            "sourcelayer": "kunstwerkdeel",
            "sld": "sld\\achtergrondvisualisatie\\14_achtergrond_kunstwerkdeel_urban_extra_polygon_ kopie.sld"
        },
        "source-layer": "kunstwerkdeel"
    },
    {
        "filter": [
            "all",
            ["==", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 0],
            ["==", "status", "bestaand"]
        ],
        "id": "overkapping kunstwerkdeel fill0",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "overkapping",
            "sourcelayer": "kunstwerkdeel",
            "sld": "sld\\achtergrondvisualisatie\\14_achtergrond_kunstwerkdeel_urban_extra_polygon_ kopie.sld"
        },
        "source-layer": "kunstwerkdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "water ondersteunendwaterdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#9BCBE9", "fill-outline-color": "#9BCBE9" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "water",
            "sourcelayer": "ondersteunendwaterdeel",
            "sld": "sld\\achtergrondvisualisatie\\2_achtergrond_ondersteunendwaterdeel_polygon.sld"
        },
        "source-layer": "ondersteunendwaterdeel"
    },
    {
        "filter": [
            "all",
            ["==", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Water_edge ondersteunendwaterdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "Water_edge",
            "sourcelayer": "ondersteunendwaterdeel",
            "sld": "sld\\achtergrondvisualisatie\\2_achtergrond_ondersteunendwaterdeel_polygon.sld"
        },
        "source-layer": "ondersteunendwaterdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "water waterdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#9BCBE9", "fill-outline-color": "#9BCBE9" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "water",
            "sourcelayer": "waterdeel",
            "sld": "sld\\achtergrondvisualisatie\\3_achtergrond_waterdeel_polygon.sld"
        },
        "source-layer": "waterdeel"
    },
    {
        "filter": [
            "all",
            ["==", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Water_edge waterdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "Water_edge",
            "sourcelayer": "waterdeel",
            "sld": "sld\\achtergrondvisualisatie\\3_achtergrond_waterdeel_polygon.sld"
        },
        "source-layer": "waterdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "onverhard"],
                ["==", "fysiek_voorkomen", "gesloten verharding"],
                ["==", "fysiek_voorkomen", "open verharding"],
                ["==", "fysiek_voorkomen", "half verhard"],
                ["==", "fysiek_voorkomen", "fruitteelt"],
                ["==", "fysiek_voorkomen", "bouwland"],
                ["==", "fysiek_voorkomen", "grasland agrarisch"],
                ["==", "fysiek_voorkomen", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-human-made onbegroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#fefefe", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-human-made",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "groenvoorziening"],
                ["==", "fysiek_voorkomen", "struiken"],
                ["==", "fysiek_voorkomen", "houtwal"],
                ["==", "fysiek_voorkomen", "grasland overig"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-low-vegetation onbegroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-low-vegetation",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "loofbos"],
                ["==", "fysiek_voorkomen", "gemengd bos"],
                ["==", "fysiek_voorkomen", "naaldbos"],
                ["==", "fysiek_voorkomen", "boomteelt"],
                ["==", "plus_fysiek_voorkomen", "bosplantsoen"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-high-vegetation onbegroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#c3dbb6", "fill-outline-color": "#c3dbb6" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-high-vegetation",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "duin"],
                ["==", "fysiek_voorkomen", "moeras"],
                ["==", "fysiek_voorkomen", "rietland"],
                ["==", "fysiek_voorkomen", "kwelder"],
                ["==", "fysiek_voorkomen", "zand"],
                ["==", "plus_fysiek_voorkomen", "zand"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "landuse-natural-sand onbegroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "landuse-natural-sand",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "heide"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-heather onbegroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#e3dce7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-heather",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z23 onbegroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#f9f9e7", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z23",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z1 onbegroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#F9F9E7", "fill-outline-color": "#F9F9E7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z1",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "onverhard"],
                ["==", "fysiek_voorkomen", "gesloten verharding"],
                ["==", "fysiek_voorkomen", "open verharding"],
                ["==", "fysiek_voorkomen", "half verhard"],
                ["==", "fysiek_voorkomen", "fruitteelt"],
                ["==", "fysiek_voorkomen", "bouwland"],
                ["==", "fysiek_voorkomen", "grasland agrarisch"],
                ["==", "fysiek_voorkomen", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-human-made begroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#fefefe", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-human-made",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "groenvoorziening"],
                ["==", "fysiek_voorkomen", "struiken"],
                ["==", "fysiek_voorkomen", "houtwal"],
                ["==", "fysiek_voorkomen", "grasland overig"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-low-vegetation begroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-low-vegetation",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "loofbos"],
                ["==", "fysiek_voorkomen", "gemengd bos"],
                ["==", "fysiek_voorkomen", "naaldbos"],
                ["==", "fysiek_voorkomen", "boomteelt"],
                ["==", "plus_fysiek_voorkomen", "bosplantsoen"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-high-vegetation begroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#c3dbb6", "fill-outline-color": "#c3dbb6" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-high-vegetation",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "duin"],
                ["==", "fysiek_voorkomen", "moeras"],
                ["==", "fysiek_voorkomen", "rietland"],
                ["==", "fysiek_voorkomen", "kwelder"],
                ["==", "fysiek_voorkomen", "zand"],
                ["==", "plus_fysiek_voorkomen", "zand"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "landuse-natural-sand begroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "landuse-natural-sand",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "heide"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-heather begroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#e3dce7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-heather",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z23 begroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#f9f9e7", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z23",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z1 begroeidterreindeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#F9F9E7", "fill-outline-color": "#F9F9E7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z1",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "id": "Tunnels en Overbrugging tunneldeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieinfra_misc",
            "id": "Tunnels en Overbrugging",
            "sourcelayer": "tunneldeel",
            "sld": "sld\\achtergrondvisualisatie\\6_achtergrond_tunneldeel_infra1_extra_polygon.sld"
        },
        "source-layer": "tunneldeel",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "id": "Tunnels en Overbrugging overbruggingsdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieinfra_misc",
            "id": "Tunnels en Overbrugging",
            "sourcelayer": "overbruggingsdeel",
            "sld": "sld\\achtergrondvisualisatie\\7_achtergrond_overbruggingsdeel_infra2_extra_polygon.sld"
        },
        "source-layer": "overbruggingsdeel",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "filter": [
            "all",
            ["==", "functie", "verkeerseiland"],
            ["!=", "fysiek_voorkomen", "groenvoorziening"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Verkeerseiland ondersteunendwegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#fdeff8", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieverkeerseiland",
            "id": "Verkeerseiland",
            "sourcelayer": "ondersteunendwegdeel",
            "sld": "sld\\achtergrondvisualisatie\\8_achtergrond_ondersteunendwegdeel_infra_side.sld"
        },
        "source-layer": "ondersteunendwegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "groenvoorziening"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Groenvoorziening ondersteunendwegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieverkeerseiland",
            "id": "Groenvoorziening",
            "sourcelayer": "ondersteunendwegdeel",
            "sld": "sld\\achtergrondvisualisatie\\8_achtergrond_ondersteunendwegdeel_infra_side.sld"
        },
        "source-layer": "ondersteunendwegdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "fysiek_voorkomen", "groenvoorziening"],
            ["!=", "functie", "verkeerseiland"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Overig wit ondersteunendwegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieverkeerseiland",
            "id": "Overig wit",
            "sourcelayer": "ondersteunendwegdeel",
            "sld": "sld\\achtergrondvisualisatie\\8_achtergrond_ondersteunendwegdeel_infra_side.sld"
        },
        "source-layer": "ondersteunendwegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic casing wegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic casing",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic Yellow fill wegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic Yellow fill",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic White fill wegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic White fill",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking casing wegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking casing",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking fill wegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking fill",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic casing wegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic casing",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic fill  wegdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#fdeff8", "fill-outline-color": "#fdeff8" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic fill ",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram z12 wegdeel line1",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.8 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram z12",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram_z1  wegdeel line1",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram_z1 ",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z23 wegdeel line1",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z23 wegdeel line1",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z1 wegdeel line1",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 3 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z1 wegdeel line1",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z23 wegdeel line1",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z23 wegdeel line1",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2,
            "line-dasharray": [11, 9]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z1 wegdeel line1",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z1 wegdeel line1",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [13, 11]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic casing spoor fill1",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic casing",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic Yellow fill spoor fill1",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic Yellow fill",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic White fill spoor fill1",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic White fill",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking casing spoor fill1",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking casing",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking fill spoor fill1",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking fill",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic casing spoor fill1",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic casing",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic fill  spoor fill1",
        "type": "fill",
        "paint": { "fill-color": "#fdeff8", "fill-outline-color": "#fdeff8" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic fill ",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram z12 spoor line1",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.8 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram z12",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram_z1  spoor line1",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram_z1 ",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z23 spoor line1",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z23 spoor line1",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z1 spoor line1",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 3 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z1 spoor line1",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z23 spoor line1",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z23 spoor line1",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2,
            "line-dasharray": [11, 9]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z1 spoor line1",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z1 spoor line1",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [13, 11]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "id": "urban_casing_z1 pand fill1",
        "type": "fill",
        "paint": { "fill-opacity": 0, "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_casing_z1",
            "sourcelayer": "pand",
            "sld": "sld\\achtergrondvisualisatie\\11_achtergrond_pand_urban_polygon.sld"
        },
        "source-layer": "pand",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "id": "urban_fill_z1 pand fill1",
        "type": "fill",
        "paint": { "fill-color": "#d3d3d3", "fill-outline-color": "#cccccc" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_fill_z1",
            "sourcelayer": "pand",
            "sld": "sld\\achtergrondvisualisatie\\11_achtergrond_pand_urban_polygon.sld"
        },
        "source-layer": "pand",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "id": "urban_z23 pand fill1",
        "type": "fill",
        "paint": { "fill-color": "#d3d3d3", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_z23",
            "sourcelayer": "pand",
            "sld": "sld\\achtergrondvisualisatie\\11_achtergrond_pand_urban_polygon.sld"
        },
        "source-layer": "pand",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z23 gebouwinstallatie fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z23",
            "sourcelayer": "gebouwinstallatie",
            "sld": "sld\\achtergrondvisualisatie\\12_achtergrond_gebouwinstallatie_urban_extra_polygon.sld"
        },
        "source-layer": "gebouwinstallatie"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z1 gebouwinstallatie fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z1",
            "sourcelayer": "gebouwinstallatie",
            "sld": "sld\\achtergrondvisualisatie\\12_achtergrond_gebouwinstallatie_urban_extra_polygon.sld"
        },
        "source-layer": "gebouwinstallatie"
    },
    {
        "filter": [
            "all",
            ["==", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "overkapping gebouwinstallatie fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "overkapping",
            "sourcelayer": "gebouwinstallatie",
            "sld": "sld\\achtergrondvisualisatie\\12_achtergrond_gebouwinstallatie_urban_extra_polygon.sld"
        },
        "source-layer": "gebouwinstallatie"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z23 overigbouwwerk fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z23",
            "sourcelayer": "overigbouwwerk",
            "sld": "sld\\achtergrondvisualisatie\\13_achtergrond_overigbouwwerk_urban_extra_polygon_kopie.sld"
        },
        "source-layer": "overigbouwwerk"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z1 overigbouwwerk fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z1",
            "sourcelayer": "overigbouwwerk",
            "sld": "sld\\achtergrondvisualisatie\\13_achtergrond_overigbouwwerk_urban_extra_polygon_kopie.sld"
        },
        "source-layer": "overigbouwwerk"
    },
    {
        "filter": [
            "all",
            ["==", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "overkapping overigbouwwerk fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "overkapping",
            "sourcelayer": "overigbouwwerk",
            "sld": "sld\\achtergrondvisualisatie\\13_achtergrond_overigbouwwerk_urban_extra_polygon_kopie.sld"
        },
        "source-layer": "overigbouwwerk"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z23 kunstwerkdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z23",
            "sourcelayer": "kunstwerkdeel",
            "sld": "sld\\achtergrondvisualisatie\\14_achtergrond_kunstwerkdeel_urban_extra_polygon_ kopie.sld"
        },
        "source-layer": "kunstwerkdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z1 kunstwerkdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z1",
            "sourcelayer": "kunstwerkdeel",
            "sld": "sld\\achtergrondvisualisatie\\14_achtergrond_kunstwerkdeel_urban_extra_polygon_ kopie.sld"
        },
        "source-layer": "kunstwerkdeel"
    },
    {
        "filter": [
            "all",
            ["==", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 1],
            ["==", "status", "bestaand"]
        ],
        "id": "overkapping kunstwerkdeel fill1",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "overkapping",
            "sourcelayer": "kunstwerkdeel",
            "sld": "sld\\achtergrondvisualisatie\\14_achtergrond_kunstwerkdeel_urban_extra_polygon_ kopie.sld"
        },
        "source-layer": "kunstwerkdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "water ondersteunendwaterdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#9BCBE9", "fill-outline-color": "#9BCBE9" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "water",
            "sourcelayer": "ondersteunendwaterdeel",
            "sld": "sld\\achtergrondvisualisatie\\2_achtergrond_ondersteunendwaterdeel_polygon.sld"
        },
        "source-layer": "ondersteunendwaterdeel"
    },
    {
        "filter": [
            "all",
            ["==", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Water_edge ondersteunendwaterdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "Water_edge",
            "sourcelayer": "ondersteunendwaterdeel",
            "sld": "sld\\achtergrondvisualisatie\\2_achtergrond_ondersteunendwaterdeel_polygon.sld"
        },
        "source-layer": "ondersteunendwaterdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "water waterdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#9BCBE9", "fill-outline-color": "#9BCBE9" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "water",
            "sourcelayer": "waterdeel",
            "sld": "sld\\achtergrondvisualisatie\\3_achtergrond_waterdeel_polygon.sld"
        },
        "source-layer": "waterdeel"
    },
    {
        "filter": [
            "all",
            ["==", "type", "oever, slootkant"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Water_edge waterdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatiewater",
            "id": "Water_edge",
            "sourcelayer": "waterdeel",
            "sld": "sld\\achtergrondvisualisatie\\3_achtergrond_waterdeel_polygon.sld"
        },
        "source-layer": "waterdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "onverhard"],
                ["==", "fysiek_voorkomen", "gesloten verharding"],
                ["==", "fysiek_voorkomen", "open verharding"],
                ["==", "fysiek_voorkomen", "half verhard"],
                ["==", "fysiek_voorkomen", "fruitteelt"],
                ["==", "fysiek_voorkomen", "bouwland"],
                ["==", "fysiek_voorkomen", "grasland agrarisch"],
                ["==", "fysiek_voorkomen", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-human-made onbegroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#fefefe", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-human-made",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "groenvoorziening"],
                ["==", "fysiek_voorkomen", "struiken"],
                ["==", "fysiek_voorkomen", "houtwal"],
                ["==", "fysiek_voorkomen", "grasland overig"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-low-vegetation onbegroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-low-vegetation",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "loofbos"],
                ["==", "fysiek_voorkomen", "gemengd bos"],
                ["==", "fysiek_voorkomen", "naaldbos"],
                ["==", "fysiek_voorkomen", "boomteelt"],
                ["==", "plus_fysiek_voorkomen", "bosplantsoen"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-high-vegetation onbegroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#c3dbb6", "fill-outline-color": "#c3dbb6" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-high-vegetation",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "duin"],
                ["==", "fysiek_voorkomen", "moeras"],
                ["==", "fysiek_voorkomen", "rietland"],
                ["==", "fysiek_voorkomen", "kwelder"],
                ["==", "fysiek_voorkomen", "zand"],
                ["==", "plus_fysiek_voorkomen", "zand"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "landuse-natural-sand onbegroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "landuse-natural-sand",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "heide"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-heather onbegroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#e3dce7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-heather",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z23 onbegroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#f9f9e7", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z23",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z1 onbegroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#F9F9E7", "fill-outline-color": "#F9F9E7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z1",
            "sourcelayer": "onbegroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\4_achtergrond_onbegroeidterreindeel_landuse_polygon.sld"
        },
        "source-layer": "onbegroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "onverhard"],
                ["==", "fysiek_voorkomen", "gesloten verharding"],
                ["==", "fysiek_voorkomen", "open verharding"],
                ["==", "fysiek_voorkomen", "half verhard"],
                ["==", "fysiek_voorkomen", "fruitteelt"],
                ["==", "fysiek_voorkomen", "bouwland"],
                ["==", "fysiek_voorkomen", "grasland agrarisch"],
                ["==", "fysiek_voorkomen", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-human-made begroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#fefefe", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-human-made",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "groenvoorziening"],
                ["==", "fysiek_voorkomen", "struiken"],
                ["==", "fysiek_voorkomen", "houtwal"],
                ["==", "fysiek_voorkomen", "grasland overig"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-low-vegetation begroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#e1eddb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-low-vegetation",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "loofbos"],
                ["==", "fysiek_voorkomen", "gemengd bos"],
                ["==", "fysiek_voorkomen", "naaldbos"],
                ["==", "fysiek_voorkomen", "boomteelt"],
                ["==", "plus_fysiek_voorkomen", "bosplantsoen"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-high-vegetation begroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#c3dbb6", "fill-outline-color": "#c3dbb6" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-high-vegetation",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "fysiek_voorkomen", "duin"],
                ["==", "fysiek_voorkomen", "moeras"],
                ["==", "fysiek_voorkomen", "rietland"],
                ["==", "fysiek_voorkomen", "kwelder"],
                ["==", "fysiek_voorkomen", "zand"],
                ["==", "plus_fysiek_voorkomen", "zand"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "landuse-natural-sand begroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "landuse-natural-sand",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "heide"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-natural-heather begroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#e3dce7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-natural-heather",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z23 begroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#f9f9e7", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z23",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "erf"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Landuse-man-made-private-z1 begroeidterreindeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#F9F9E7", "fill-outline-color": "#F9F9E7" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieLanduse",
            "id": "Landuse-man-made-private-z1",
            "sourcelayer": "begroeidterreindeel",
            "sld": "sld\\achtergrondvisualisatie\\5_achtergrond_begroeidterreindeel_landuse2_polygon.sld"
        },
        "source-layer": "begroeidterreindeel"
    },
    {
        "id": "Tunnels en Overbrugging tunneldeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieinfra_misc",
            "id": "Tunnels en Overbrugging",
            "sourcelayer": "tunneldeel",
            "sld": "sld\\achtergrondvisualisatie\\6_achtergrond_tunneldeel_infra1_extra_polygon.sld"
        },
        "source-layer": "tunneldeel",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "id": "Tunnels en Overbrugging overbruggingsdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieinfra_misc",
            "id": "Tunnels en Overbrugging",
            "sourcelayer": "overbruggingsdeel",
            "sld": "sld\\achtergrondvisualisatie\\7_achtergrond_overbruggingsdeel_infra2_extra_polygon.sld"
        },
        "source-layer": "overbruggingsdeel",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "filter": [
            "all",
            ["==", "functie", "verkeerseiland"],
            ["!=", "fysiek_voorkomen", "groenvoorziening"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Verkeerseiland ondersteunendwegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#fdeff8", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieverkeerseiland",
            "id": "Verkeerseiland",
            "sourcelayer": "ondersteunendwegdeel",
            "sld": "sld\\achtergrondvisualisatie\\8_achtergrond_ondersteunendwegdeel_infra_side.sld"
        },
        "source-layer": "ondersteunendwegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "fysiek_voorkomen", "groenvoorziening"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Groenvoorziening ondersteunendwegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e1eddb", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieverkeerseiland",
            "id": "Groenvoorziening",
            "sourcelayer": "ondersteunendwegdeel",
            "sld": "sld\\achtergrondvisualisatie\\8_achtergrond_ondersteunendwegdeel_infra_side.sld"
        },
        "source-layer": "ondersteunendwegdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "fysiek_voorkomen", "groenvoorziening"],
            ["!=", "functie", "verkeerseiland"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Overig wit ondersteunendwegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieverkeerseiland",
            "id": "Overig wit",
            "sourcelayer": "ondersteunendwegdeel",
            "sld": "sld\\achtergrondvisualisatie\\8_achtergrond_ondersteunendwegdeel_infra_side.sld"
        },
        "source-layer": "ondersteunendwegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic casing wegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic casing",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic Yellow fill wegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic Yellow fill",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic White fill wegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic White fill",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking casing wegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking casing",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking fill wegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking fill",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic casing wegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic casing",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic fill  wegdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#fdeff8", "fill-outline-color": "#fdeff8" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic fill ",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram z12 wegdeel line2",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.8 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram z12",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram_z1  wegdeel line2",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram_z1 ",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z23 wegdeel line2",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z23 wegdeel line2",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z1 wegdeel line2",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 3 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z1 wegdeel line2",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z23 wegdeel line2",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z23 wegdeel line2",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2,
            "line-dasharray": [11, 9]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z23",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z1 wegdeel line2",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z1 wegdeel line2",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [13, 11]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z1",
            "sourcelayer": "wegdeel",
            "sld": "sld\\achtergrondvisualisatie\\9_achtergrond_wegdeel_infra.sld"
        },
        "source-layer": "wegdeel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic casing spoor fill2",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic casing",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "rijbaan regionale weg"],
                ["==", "functie", "rijbaan autosnelweg"],
                ["==", "functie", "rijbaan autoweg"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic Yellow fill spoor fill2",
        "type": "fill",
        "paint": { "fill-color": "#fdf6bb", "fill-outline-color": "#fdf6bb" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic Yellow fill",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "fietspad"],
                ["==", "functie", "inrit"],
                ["==", "functie", "parkeervlak"],
                ["==", "functie", "rijbaan lokale weg"],
                ["==", "functie", "overweg"],
                ["==", "functie", "OV-baan"],
                ["==", "functie", "spoorbaan"],
                ["==", "functie", "transitie"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Motorized Traffic White fill spoor fill2",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Motorized Traffic White fill",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking casing spoor fill2",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking casing",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "parkeervlak"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Parking fill spoor fill2",
        "type": "fill",
        "paint": { "fill-color": "#ffffff", "fill-outline-color": "#ffffff" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Parking fill",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic casing spoor fill2",
        "type": "fill",
        "paint": { "fill-color": "#d1c1be", "fill-outline-color": "#d1c1be" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic casing",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "functie", "voetpad"],
                ["==", "functie", "ruiterpad"],
                ["==", "functie", "voetgangersgebied"],
                ["==", "functie", "voetpad op trap"],
                ["==", "functie", "woonerf"]
            ],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Un-motorized Traffic fill  spoor fill2",
        "type": "fill",
        "paint": { "fill-color": "#fdeff8", "fill-outline-color": "#fdeff8" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Un-motorized Traffic fill ",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram z12 spoor line2",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.8 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram z12",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "tram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Tram_z1  spoor line2",
        "type": "line",
        "paint": { "line-color": "#999999", "line-width": 0.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Tram_z1 ",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z23 spoor line2",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z23 spoor line2",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram casing_z1 spoor line2",
        "type": "line",
        "paint": { "line-color": "#cccccc", "line-width": 3 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram casing_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["==", "functie", "sneltram"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Sneltram fill_z1 spoor line2",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [10, 8]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Sneltram fill_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z23 spoor line2",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4.5 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z23 spoor line2",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 2,
            "line-dasharray": [11, 9]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z23",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein Casing_z1 spoor line2",
        "type": "line",
        "paint": { "line-color": "#b4b4b4", "line-width": 4 },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein Casing_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "filter": [
            "all",
            ["any", ["==", "functie", "trein"], ["==", "functie", "niet-bgt"]],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "Trein fill_z1 spoor line2",
        "type": "line",
        "paint": {
            "line-color": "#ffffff",
            "line-width": 1.5,
            "line-dasharray": [13, 11]
        },
        "layout": { "line-cap": "butt", "line-join": "miter" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieTraffic",
            "id": "Trein fill_z1",
            "sourcelayer": "spoor",
            "sld": "sld\\achtergrondvisualisatie\\10_achtergrond_spoor_infra.sld"
        },
        "source-layer": "spoor"
    },
    {
        "id": "urban_casing_z1 pand fill2",
        "type": "fill",
        "paint": { "fill-opacity": 0, "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_casing_z1",
            "sourcelayer": "pand",
            "sld": "sld\\achtergrondvisualisatie\\11_achtergrond_pand_urban_polygon.sld"
        },
        "source-layer": "pand",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "id": "urban_fill_z1 pand fill2",
        "type": "fill",
        "paint": { "fill-color": "#d3d3d3", "fill-outline-color": "#cccccc" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_fill_z1",
            "sourcelayer": "pand",
            "sld": "sld\\achtergrondvisualisatie\\11_achtergrond_pand_urban_polygon.sld"
        },
        "source-layer": "pand",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "id": "urban_z23 pand fill2",
        "type": "fill",
        "paint": { "fill-color": "#d3d3d3", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_z23",
            "sourcelayer": "pand",
            "sld": "sld\\achtergrondvisualisatie\\11_achtergrond_pand_urban_polygon.sld"
        },
        "source-layer": "pand",
        "filter": [
            "all",
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ]
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z23 gebouwinstallatie fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z23",
            "sourcelayer": "gebouwinstallatie",
            "sld": "sld\\achtergrondvisualisatie\\12_achtergrond_gebouwinstallatie_urban_extra_polygon.sld"
        },
        "source-layer": "gebouwinstallatie"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z1 gebouwinstallatie fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z1",
            "sourcelayer": "gebouwinstallatie",
            "sld": "sld\\achtergrondvisualisatie\\12_achtergrond_gebouwinstallatie_urban_extra_polygon.sld"
        },
        "source-layer": "gebouwinstallatie"
    },
    {
        "filter": [
            "all",
            ["==", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "overkapping gebouwinstallatie fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "overkapping",
            "sourcelayer": "gebouwinstallatie",
            "sld": "sld\\achtergrondvisualisatie\\12_achtergrond_gebouwinstallatie_urban_extra_polygon.sld"
        },
        "source-layer": "gebouwinstallatie"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z23 overigbouwwerk fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z23",
            "sourcelayer": "overigbouwwerk",
            "sld": "sld\\achtergrondvisualisatie\\13_achtergrond_overigbouwwerk_urban_extra_polygon_kopie.sld"
        },
        "source-layer": "overigbouwwerk"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z1 overigbouwwerk fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z1",
            "sourcelayer": "overigbouwwerk",
            "sld": "sld\\achtergrondvisualisatie\\13_achtergrond_overigbouwwerk_urban_extra_polygon_kopie.sld"
        },
        "source-layer": "overigbouwwerk"
    },
    {
        "filter": [
            "all",
            ["==", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "overkapping overigbouwwerk fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "overkapping",
            "sourcelayer": "overigbouwwerk",
            "sld": "sld\\achtergrondvisualisatie\\13_achtergrond_overigbouwwerk_urban_extra_polygon_kopie.sld"
        },
        "source-layer": "overigbouwwerk"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z23 kunstwerkdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z23",
            "sourcelayer": "kunstwerkdeel",
            "sld": "sld\\achtergrondvisualisatie\\14_achtergrond_kunstwerkdeel_urban_extra_polygon_ kopie.sld"
        },
        "source-layer": "kunstwerkdeel"
    },
    {
        "filter": [
            "all",
            ["!=", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "urban_extra_z1 kunstwerkdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "urban_extra_z1",
            "sourcelayer": "kunstwerkdeel",
            "sld": "sld\\achtergrondvisualisatie\\14_achtergrond_kunstwerkdeel_urban_extra_polygon_ kopie.sld"
        },
        "source-layer": "kunstwerkdeel"
    },
    {
        "filter": [
            "all",
            ["==", "type", "overkapping"],
            ["==", "relatieve_hoogteligging", 2],
            ["==", "status", "bestaand"]
        ],
        "id": "overkapping kunstwerkdeel fill2",
        "type": "fill",
        "paint": { "fill-color": "#e3dce7", "fill-outline-color": "#b4b4b4" },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatieurban",
            "id": "overkapping",
            "sourcelayer": "kunstwerkdeel",
            "sld": "sld\\achtergrondvisualisatie\\14_achtergrond_kunstwerkdeel_urban_extra_polygon_ kopie.sld"
        },
        "source-layer": "kunstwerkdeel"
    },
    {
        "id": "labels_z1 pand_nummeraanduiding symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{tekst}",
            "text-font": ["Inter Regular"],
            "text-size": 12,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatielabels",
            "id": "labels_z1",
            "sourcelayer": "pand_nummeraanduiding",
            "sld": "sld\\achtergrondvisualisatie\\21_achtergrond_nummeraanduidingreeks_labels_numbers.sld"
        },
        "source-layer": "pand_nummeraanduiding",
        "filter": ["==", "status", "bestaand"]
    },
    {
        "id": "labels_z2 pand_nummeraanduiding symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{tekst}",
            "text-font": ["Inter Regular"],
            "text-size": 12,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatielabels",
            "id": "labels_z2",
            "sourcelayer": "pand_nummeraanduiding",
            "sld": "sld\\achtergrondvisualisatie\\21_achtergrond_nummeraanduidingreeks_labels_numbers.sld"
        },
        "source-layer": "pand_nummeraanduiding",
        "filter": ["==", "status", "bestaand"]
    },
    {
        "id": "labels_z3 pand_nummeraanduiding symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{tekst}",
            "text-font": ["Inter Regular"],
            "text-size": 12,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond Visualisatielabels",
            "id": "labels_z3",
            "sourcelayer": "pand_nummeraanduiding",
            "sld": "sld\\achtergrondvisualisatie\\21_achtergrond_nummeraanduidingreeks_labels_numbers.sld"
        },
        "source-layer": "pand_nummeraanduiding",
        "filter": ["==", "status", "bestaand"]
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "openbareruimtetype", "Kunstwerk"],
                ["==", "openbareruimtetype", "Spoorbaan"],
                ["==", "openbareruimtetype", "Weg"]
            ],
            ["==", "status", "bestaand"]
        ],
        "id": "label_infrastructure_z3 openbareruimtelabel symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{openbareruimtenaam}",
            "text-font": ["Inter Regular"],
            "text-size": 19,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieVisualisatie van de namen in de openbare ruimte",
            "id": "label_infrastructure_z3",
            "sourcelayer": "openbareruimtelabel",
            "sld": "sld\\achtergrondvisualisatie\\20_achtergrond_openbareruimtelabel_label_names.sld"
        },
        "source-layer": "openbareruimtelabel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "openbareruimtetype", "Kunstwerk"],
                ["==", "openbareruimtetype", "Spoorbaan"],
                ["==", "openbareruimtetype", "Weg"]
            ],
            ["==", "status", "bestaand"]
        ],
        "id": "infrastructure_z2 openbareruimtelabel symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{openbareruimtenaam}",
            "text-font": ["Inter Regular"],
            "text-size": 17,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieVisualisatie van de namen in de openbare ruimte",
            "id": "infrastructure_z2",
            "sourcelayer": "openbareruimtelabel",
            "sld": "sld\\achtergrondvisualisatie\\20_achtergrond_openbareruimtelabel_label_names.sld"
        },
        "source-layer": "openbareruimtelabel"
    },
    {
        "filter": [
            "all",
            [
                "any",
                ["==", "openbareruimtetype", "Kunstwerk"],
                ["==", "openbareruimtetype", "Spoorbaan"],
                ["==", "openbareruimtetype", "Weg"]
            ],
            ["==", "status", "bestaand"]
        ],
        "id": "infrastructure_z1 openbareruimtelabel symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{openbareruimtenaam}",
            "text-font": ["Inter Regular"],
            "text-size": 15,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieVisualisatie van de namen in de openbare ruimte",
            "id": "infrastructure_z1",
            "sourcelayer": "openbareruimtelabel",
            "sld": "sld\\achtergrondvisualisatie\\20_achtergrond_openbareruimtelabel_label_names.sld"
        },
        "source-layer": "openbareruimtelabel"
    },
    {
        "filter": [
            "all",
            ["==", "openbareruimtetype", "Terrein"],
            ["==", "status", "bestaand"]
        ],
        "id": "manmade_terrain_z123 openbareruimtelabel symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{openbareruimtenaam}",
            "text-font": ["Inter Regular"],
            "text-size": 17,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieVisualisatie van de namen in de openbare ruimte",
            "id": "manmade_terrain_z123",
            "sourcelayer": "openbareruimtelabel",
            "sld": "sld\\achtergrondvisualisatie\\20_achtergrond_openbareruimtelabel_label_names.sld"
        },
        "source-layer": "openbareruimtelabel"
    },
    {
        "filter": [
            "all",
            ["==", "openbareruimtetype", "Landschappelijk gebied"],
            ["==", "status", "bestaand"]
        ],
        "id": "natural_terrain_z123 openbareruimtelabel symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{openbareruimtenaam}",
            "text-font": ["Inter Regular"],
            "text-size": 17,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieVisualisatie van de namen in de openbare ruimte",
            "id": "natural_terrain_z123",
            "sourcelayer": "openbareruimtelabel",
            "sld": "sld\\achtergrondvisualisatie\\20_achtergrond_openbareruimtelabel_label_names.sld"
        },
        "source-layer": "openbareruimtelabel"
    },
    {
        "filter": [
            "all",
            ["==", "openbareruimtetype", "Water"],
            ["==", "status", "bestaand"]
        ],
        "id": "Water_z23 openbareruimtelabel symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{openbareruimtenaam}",
            "text-font": ["Inter Regular"],
            "text-size": 20,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieVisualisatie van de namen in de openbare ruimte",
            "id": "Water_z23",
            "sourcelayer": "openbareruimtelabel",
            "sld": "sld\\achtergrondvisualisatie\\20_achtergrond_openbareruimtelabel_label_names.sld"
        },
        "source-layer": "openbareruimtelabel"
    },
    {
        "filter": [
            "all",
            ["==", "openbareruimtetype", "Water"],
            ["==", "status", "bestaand"]
        ],
        "id": "Water_z1 openbareruimtelabel symbol1000",
        "type": "symbol",
        "paint": { "text-opacity": 1, "text-color": "#000000" },
        "layout": {
            "text-field": "{openbareruimtenaam}",
            "text-font": ["Inter Regular"],
            "text-size": 18,
            "text-rotate": ["-", 360, ["get", "hoek"]]
        },
        "source": "bgt",
        "metadata": {
            "name": "Achtergrond VisualisatieVisualisatie van de namen in de openbare ruimte",
            "id": "Water_z1",
            "sourcelayer": "openbareruimtelabel",
            "sld": "sld\\achtergrondvisualisatie\\20_achtergrond_openbareruimtelabel_label_names.sld"
        },
        "source-layer": "openbareruimtelabel"
    }
];

export const BGT_STYLE = {
    "version": 8,
    "name": "BGT Achtergrond Visualisatie",
    "id": "bgt_achtergrondvisualisatie",
    "zoom": 17,
    "pitch": 50,
    "center": [0, 0],
    "sprite": "https://api.pdok.nl/lv/bgt/ogc/v1_0/resources/standaardsprites/sprites",
    "glyphs": "https://api.pdok.nl/lv/bgt/ogc/v1_0/resources/fonts/{fontstack}/{range}.pbf",
    "layers": BGT_LAYERS,
    "sources": {
        "bgt": {
            "type": "vector",
            "tiles": [
                "https://api.pdok.nl/lv/bgt/ogc/v1_0/tiles/WebMercatorQuad/{z}/{y}/{x}?f=mvt"
            ],
            minzoom: 17,
            maxzoom: 17
        },
    }
} as const;