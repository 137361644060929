export const SEO = {
    DEFAULT_TITLE: 'Mister KLIC - De alles-in-1 oplossing voor KLIC-meldingen',
    DEFAULT_DESCRIPTION: 'Beheer uw KLIC-meldingen op kantoor, bekijk uw KLIC-meldingen op de graaflocatie en exporteer KLIC-meldingen naar AutoCAD, Revit en QGIS via DWG, Geopackage, WMS en WFS.',
    DEFAULT_IMAGE: 'assets/mister-klic-nieuws.png'
};

export const SUPPORT = 'support@misterklic.nl';

export const KVK = '65533135';
export const STREET = 'Nieuwegracht 2b';
export const POSTALCODE = '3512 LP';
export const CITY = 'Utrecht';
