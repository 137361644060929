
export interface AuthUser {
  email: string;
  sub: string;
  organisationId: string | null;
  roles: string[];
  iat: number;
  exp: number,
  promptPasswordReset: boolean;
}

export interface AuthStateModel {
  currentUser: AuthUser | null;
  token: string | null;
}

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { email: string; password: string }) { }
}

export class ChangePassword {
  static readonly type = '[Auth] ChangePassword';
  constructor(public payload: { email: string; password: string }) { }
}

export class MagicLogin {
  static readonly type = '[Auth] MagicLogin';
  constructor(public payload: { url: string }) { }
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class LostPassword {
  static readonly type = '[Auth] LostPassword';
  constructor(public payload: {
    email: string
  }) { }
}
export class ResetPassword {
  static readonly type = '[Auth] ResetPassword';
  constructor(public payload: {
    password: string;
    userId: string;
    token: string;
  }) { }

}


