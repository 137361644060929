import { Geometry } from "geojson";

export interface ShopStateModel {
    type: 'graafmelding' | 'calamiteitenmelding' | 'orientatieverzoek';
    startDate: string;
    endDate: string;
    activities: string[];
    geometries: Geometry[];
    addedOn: string;
    closestAddressIds: string[];
    notitie: string;
    reference: string;
    projectId: string | null;
}

export class SetShoppingCartContent {
    static readonly type = '[Shop] Set cart state'
    constructor(public payload: Partial<ShopStateModel>) { }
}

export class ResetShop {
    static readonly type = '[Shop] Reset Shop'
    constructor() { }
}

