import { TodosState } from './core/states/todos.state';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AuthState } from './core/states/auth.state';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { OrganisationsState } from './core/states/organisations.state';
import { UsersState } from './core/states/users.state';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { UploadsState } from './core/states/uploads.state';
import { IMAGE_CONFIG, registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl'
import { SusbcriptionTypesState } from './core/states/subscription-types.state';
import { RequestCountInterceptor } from './core/interceptors/request-count.interceptor';
import { GenericErrorHandler } from './core/error-handlers/generic-error.handler';
import { LayoutModule } from './layout/layout.module';
import { AttachmentState } from './core/states/attachments.state';
import { SelectionState } from './core/states/selection.state';
import { KlicMetadataState } from './core/states/klic-metadata';
import { AppState } from './core/states/app.state';
import { MatIconRegistry } from '@angular/material/icon';
import { ProjectsState } from './core/states/project.state';
import { ShopState } from './core/states/shop.state';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MapState } from './core/states/map.state';
import { StateNames } from './core/states/models/state-names.enum';

const formFieldDefaults: MatFormFieldDefaultOptions = {
    appearance: 'fill',
    floatLabel: 'always'
};

//Zonder dit werkt de locale nl-NL niet.
registerLocaleData(localeNl)

@NgModule({
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        PagesModule,
        CoreModule,
        LayoutModule,
        NgxsModule.forRoot([
            AuthState,
            OrganisationsState,
            UsersState,
            UploadsState,
            SusbcriptionTypesState,
            AttachmentState,
            SelectionState,
            KlicMetadataState,
            AppState,
            ProjectsState,
            TodosState,
            ShopState,
            ProjectsState,
            MapState
        ], {
            selectorOptions: {
                injectContainerState: false,
                suppressErrors: false,
            },
            developmentMode: !environment.production,
            executionStrategy: NoopNgxsExecutionStrategy
        }),
        NgxsStoragePluginModule.forRoot({
            keys: [
              StateNames.shop,
              StateNames.auth,
              StateNames.app_v4,
            ],
            migrations: []
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production === true
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
            { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: RequestCountInterceptor, multi: true },
            { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: formFieldDefaults },
            { provide: LOCALE_ID, useValue: "nl-NL" },
            { provide: ErrorHandler, useClass: GenericErrorHandler },
            provideHttpClient(withInterceptorsFromDi()),
        ]

})
export class AppModule {
    constructor(iconRegistry: MatIconRegistry) {
        iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    }
}
