import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, scan } from 'rxjs';
import { CoreModule } from '../core.module';
import { UploadState } from '../interfaces/upload-state.interface';
import proj4 from 'proj4';
import { PROJECTIONS } from '../config';

@Injectable({
  providedIn: CoreModule
})
export class UtilsService {
  constructor() {
  }

  public objectValuesAsString(obj: any): string {
    return Object.values(obj).reduce((prev, curr) => {
      if (typeof curr !== 'object' && curr !== null) {
        return prev += ` ${curr}`;
      } else {
        return prev;
      }
    }, '') as string;
  }

  public upload(): (
    source: Observable<HttpEvent<unknown>>
  ) => Observable<UploadState> {
    const initialState: UploadState = { state: 'PENDING', progress: 0 };

    const calculateUploadState = (upload: UploadState, event: HttpEvent<unknown>): UploadState => {
      if (this.isHttpProgressEvent(event)) {
        return {
          progress: event.total
            ? Math.round((100 * event.loaded) / event.total)
            : upload.progress,
          state: 'IN_PROGRESS',
        }
      }
      if (this.isHttpResponse(event)) {
        return {
          progress: 100,
          state: 'DONE',
          body: event.body
        }
      }
      return upload
    }

    return (source) => source.pipe(scan(calculateUploadState, initialState))
  }


  public isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response
  }

  public isHttpProgressEvent(
    event: HttpEvent<unknown>
  ): event is HttpProgressEvent {
    return (
      event.type === HttpEventType.DownloadProgress ||
      event.type === HttpEventType.UploadProgress
    )
  }

  public geometryArrayToFeatureCollection(items: { geometry: any, properties?: any }[]) {
    return {
      type: "FeatureCollection",
      features: items.map(item => {
        return {
          type: "Feature",
          geometry: item.geometry,
          properties: item.properties
        }
      })
    }
  }

  public uuidv4(): string {
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  public hexToRgb(hex: string) {
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  }

  public downloadFile(download: Blob, fileName: string) {
    var link = document.createElement('a');
    link.href = URL.createObjectURL(download);
    link.download = fileName;
    link.click();
  }

  public reprojectWgsToRd(coordpair: [number, number]) {
    return proj4(PROJECTIONS.RD).forward(coordpair);
  }

  public distanceBetweenPoints(point1: [number, number], point2: [number, number]) { 
    // Calculate the distance between two points on a a flat surface
    // The distance is calculated in meters
    // The formula used is the Pythagorean theorem
    const x1 = point1[0];
    const y1 = point1[1];
    const x2 = point2[0];
    const y2 = point2[1];

    const x = x2 - x1;
    const y = y2 - y1;

    return Math.sqrt(x * x + y * y);


  }
}



