import { KlicAttachment, ExtraDetails } from "../../interfaces/attachment.interface";

export interface AttachmentsStateModel {
    attachments: KlicAttachment[];
    extraDetails: ExtraDetails[];
}

export class ListKlicAttachments {
    static readonly type = '[Attachments] List All'
    constructor(public payload: {uploadId: string}) { }
}

export class ListKlicExtraDetails {
    static readonly type = '[Extra Details] List All'
    constructor(public payload: {uploadId: string}) { }
}