import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { AuthStateModel, AuthUser, ChangePassword, Login, Logout, LostPassword, MagicLogin, ResetPassword } from "./models/auth.state.model";
import { tap } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { AuthService } from "../services/auth.service";
import { Roles } from "../interfaces/roles.enum";
import { firstValueFrom } from "rxjs";
import { StateNames } from "./models/state-names.enum";

const defaultAuthState = {
  currentUser: null,
  token: null,
};

@State<AuthStateModel>({
  name: StateNames.auth,
  defaults: defaultAuthState
})
@Injectable()
export class AuthState {
  @Selector([AuthState])
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector([AuthState])
  static currentUser(state: AuthStateModel): AuthUser | null {
    return state.currentUser;
  }

  @Selector([AuthState])
  static isFirstRun(state: AuthStateModel): boolean {
    return state.currentUser?.organisationId === null;
  }

  @Selector([AuthState])
  static promptPasswordReset(state: AuthStateModel): boolean {
    return state.currentUser?.promptPasswordReset === true;
  }


  @Selector([AuthState])
  static isAuthenticated(state: AuthStateModel): boolean {
    let expired = false;
    if (!!state.token) {
      const decoded: { iat: number, exp: number } = jwt_decode(state.token);
      const now = new Date().getTime();
      const exp = decoded.exp * 1000;
      const leniency = 1000 * 60 * 30; // 30 minutes;
      expired = (now + leniency) > exp;
    }
    return !!state.token && !expired;
  }

  static hasOneOfRoles(roleNames: Roles[]) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      const hasRoleChecks = roleNames.map(roleName => {
        return state.currentUser!.roles.includes(roleName);
      });
      return hasRoleChecks.includes(true);
    });
  }


  constructor(private authService: AuthService) { }

  @Action(Login)
  async login(ctx: StateContext<AuthStateModel>, action: Login) {
    const loginResponse = await firstValueFrom(this.authService.login(action.payload));
    ctx.setState(loginResponse);
  }
  
  @Action(ChangePassword)
  async ChangePassword(ctx: StateContext<AuthStateModel>, action: ChangePassword) {
    const loginResponse = await firstValueFrom(this.authService.changePassword(action.payload));
    ctx.setState(loginResponse);
  }

  @Action(MagicLogin)
  async magicLogin(ctx: StateContext<AuthStateModel>, action: MagicLogin) {
    const loginResponse = await firstValueFrom(this.authService.magicLogin(action.payload));
    ctx.setState(loginResponse);
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    return ctx.setState(defaultAuthState);
  }


  @Action(LostPassword)
  lostPassword(ctx: StateContext<AuthStateModel>, action: LostPassword) {
    return this.authService.lostPassword(action.payload).pipe(
      tap()
    )
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<AuthStateModel>, action: ResetPassword) {
    return this.authService.resetPassword(action.payload).pipe(
      tap()
    )
  }
}
