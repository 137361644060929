import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { ApiService } from "../services/api.service";
import { firstValueFrom } from "rxjs";
import produce from "immer";
import { CreateProject, CreateProjectDesign, DeleteProject, ListProjects, ProjectsStateModel, UpdateProject } from "./models/projects.state.model";

const defaultProjectsState = {
  projects: {},
};

@State<ProjectsStateModel>({
  name: 'projects',
  defaults: defaultProjectsState
})

@Injectable()
export class ProjectsState {
  @Selector([ProjectsState])
  static projects(state: ProjectsStateModel) {
    return Object.values(state.projects);
  }

  static getProjectById(projectId: string) {
    return createSelector([ProjectsState], (state: ProjectsStateModel) => {
      return state.projects[projectId];
    });
  }

  constructor(
    private apiService: ApiService
  ) { }

  @Action(ListProjects)
  async listProjects(ctx: StateContext<ProjectsStateModel>, action: ListProjects) {
    const projects = await firstValueFrom(this.apiService.listProjectsInOrganisation({organisationId: action.payload.organisationId}));
    const projectsMap = projects.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.id]: curr
      }
    }, {});
    ctx.patchState({ projects: projectsMap });
  }

  @Action(CreateProject)
  async createProject(ctx: StateContext<ProjectsStateModel>, action: CreateProject) {
    const project = await firstValueFrom(this.apiService.createProjectInOrganisation(action.payload.organisationId, action.payload));
    const state = produce(ctx.getState(), draft => {
      draft.projects[project.id] = project;
    });
    ctx.setState(state);
  }

  
  @Action(UpdateProject)
  async updateProject(ctx: StateContext<ProjectsStateModel>, action: UpdateProject) {
    const project = await firstValueFrom(this.apiService.updateProjectInOrganisation(action.payload.organisationId, {
      id: action.payload.id, 
      name: action.payload.name, 
      description: action.payload.description,
      code: action.payload.code,
      fileIds: action.payload.fileIds
    }));
    const state = produce(ctx.getState(), draft => {
      draft.projects[project.id] = project;
    });
    ctx.setState(state);
  }

  @Action(DeleteProject)
  async deleteProject(ctx: StateContext<ProjectsStateModel>, action: DeleteProject) {
    const project = await firstValueFrom(this.apiService.deleteProjectInOrganisation(action.payload.organisationId, action.payload.projectId));
    const state = produce(ctx.getState(), draft => {
      delete draft.projects[action.payload.projectId];
    });
    ctx.setState(state);
  }

  @Action(CreateProjectDesign)
  async createProjectDesign(ctx: StateContext<ProjectsStateModel>, action: CreateProjectDesign) {
    const project = await firstValueFrom(this.apiService.createProjectDesign({
      organisationId: action.payload.organisationId, 
      projectId: action.payload.projectId, 
      featureCollection: action.payload.featureCollection
    }));

    const state = produce(ctx.getState(), draft => {
      draft.projects[action.payload.projectId] = project;
    });
    ctx.setState(state);
  }

}
