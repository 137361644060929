import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { VERSION } from './core/config';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { SeoService } from './core/services/seo.service';
import { Logout } from './core/states/models/auth.state.model';
import { filter, map, take } from 'rxjs';
import { AuthState } from './core/states/auth.state';
import { CreateTodo, UpdateTodo } from './core/states/models/todos.state.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateProject, UpdateProject } from './core/states/models/projects.state.model';
import { LeadInfoService } from './core/services/leadinfo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private actions: Actions,
    private router: Router,
    private seo: SeoService,
    private googleAnalytics: GoogleAnalyticsService,
    private leadinfoService: LeadInfoService,
    private store: Store,
    private snackbar: MatSnackBar
  ) {
    console.log(`%c version: ${VERSION}`, 'background: #222; color: #bada55');
  }  
  
  ngOnInit() {

    const userId$ = this.store.select(AuthState.currentUser).pipe(map(user => user?.sub));
    this.googleAnalytics.enableTracking( userId$ );
    this.leadinfoService.addLeadinfoTag()
    this.actions.pipe(
      ofActionSuccessful(Logout),
    ).subscribe(() => {
      this.router.navigate(['/']);
      this.snackbar.open(`U bent uitgelogd.`, 'OK', { duration: 4500})
    });
    // Adds all SEO data to HTML. SEO data is defined in the router module.
    this.seo.addSeoData();

    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      this.redirectToDashboardOnLoad();
    }

    this.addNotifications();

  }
  


  private redirectToDashboardOnLoad() {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      take(1),
      map((event) => (event as NavigationStart).url),
      filter(url => url === '/')
      ).subscribe(() => {
        this.router.navigate(['/klic'])
      })
  }

  private addNotifications() {
    this.actions.pipe(ofActionSuccessful(CreateTodo)).subscribe((message: CreateTodo) => {
      this.snackbar.open(`${message.payload.todo.todoType} toegevoegd.`, 'OK', {duration: 4500});
    });

    this.actions.pipe(ofActionSuccessful(UpdateTodo)).subscribe(() => {
      this.snackbar.open('Uw wijzigingen zijn opgeslagen.', 'OK', {duration: 4500});
    });

    this.actions.pipe(ofActionSuccessful(UpdateProject)).subscribe(() => {
      this.snackbar.open('Uw wijzigingen zijn opgeslagen.', 'OK', {duration: 4500});
    });

    this.actions.pipe(ofActionSuccessful(CreateProject)).subscribe(() => {
      // this.snackbar.open('Uw project is aangemaakt.', 'OK', {duration: 4500});
    });
  }

}
