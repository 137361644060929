import { RiskAssessment } from './../interfaces/risk-assessment.interface';
import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { KvK, KvKAddress, Organisation, OrganisationAdminStatistics } from '../interfaces/organisation.interface';
import { Role, User } from '../interfaces/user.interface';
import { Upload } from '../interfaces/upload.interface';
import { UtilsService } from './utils.service';
import { MrKLICSubscription, SubscriptionType } from '../interfaces/subscription.interface';
import { KlicAttachment, ExtraDetails } from '../interfaces/attachment.interface';
import { KlicNetworkObject, KlicPrecaution } from '../states/models/selection.state.model';
import { UpdateUserParams } from '../states/models/users.state.model';
import { Project, ProjectReportData } from '../interfaces/project.interface';
import { Design } from '../interfaces/design.interface';
import { Todo } from '../interfaces/todo.interface';
import { OrderItem } from '../interfaces/order-item.interface';
import { MrklicFile } from '../interfaces/file.interface';
import { firstValueFrom } from 'rxjs';
import { FeatureCollection } from 'geojson';
import { KlicMetadataStateModel } from '../states/models/klic-metadata.state.model';
import { ExportConfiguration, ExportFilter, ExportFormats, ExportTypes, FMEDownloadTypes } from '../interfaces/export-types.enum';

@Injectable({
  providedIn: CoreModule
})
export class ApiService {
  private baseUrl = environment.apiBaseUrl + '/api/v1';;
  constructor(private http: HttpClient, private utilsService: UtilsService) {
  }
  /* Subscriptions (currently, only the admin can use these routes */
  public createSubscription(createSubscriptionParams: {
    subscriptionTypeName: string,
    organisationId: string,
    status: string,
    customAvailableSlots: number,
    customPricePerMonth?: string,
    validFrom: string,
    validUntil?: string
  }) {
    return this.http.post<MrKLICSubscription>(`${this.baseUrl}/subscriptions`, createSubscriptionParams);
  }

  public updateSubscription(subscriptionId: string, updateParams: { status?: string, customAvailableSlots?: number }) {
    return this.http.patch<void>(`${this.baseUrl}/subscriptions/${subscriptionId}`, updateParams);
  }

  public cancelSubscription(subscriptionId: string) {
    return this.http.delete<void>(`${this.baseUrl}/subscriptions/${subscriptionId}`);
  }

  /* Organisations */
  public listOrganisations() {
    return this.http.get<Organisation[]>(`${this.baseUrl}/organisations`);
  }

  public getOrganisationById(organisationId: string) {
    return this.http.get<Organisation>(`${this.baseUrl}/organisations/${organisationId}`);
  }

  public getOrganisationAdminStatisticsById(organisationId: string) {
    return this.http.get<OrganisationAdminStatistics[]>(`${this.baseUrl}/organisations/${organisationId}/admin-statistics`);

  }

  public updateOrganisation(organisationId: string, organisationUpdateParams: Partial<Organisation>, fileIds: string[] | undefined) {
    return this.http.put<Organisation>(`${this.baseUrl}/organisations/${organisationId}`, { ...organisationUpdateParams, fileIds });
  }

  public listUploadsForOrganisation(params: { organisationId: string, params: { projectId?: string, statusses?: string[], q?: string, aanvraagSoort?: string, evCriteria?: string } }) {
    return this.http.get<Upload[]>(`${this.baseUrl}/organisations/${params.organisationId}/uploads`, {
      params: params.params
    });
  }

  public getUploadsCsvFile(params: { organisationId: string, params: { projectId?: string, statusses?: string[], q?: string, aanvraagSoort?: string, evCriteria?: string } }) {
    return this.http.get<Blob>(`${this.baseUrl}/organisations/${params.organisationId}/uploads-export`, {
      params: params.params,
      responseType: 'blob' as any
    });
  }

  public listUsersInOrganisation(params: { organisationId: string }) {
    return this.http.get<User[]>(`${this.baseUrl}/organisations/${params.organisationId}/users`);
  }

  public listProjectsInOrganisation(params: { organisationId: string }) {
    return this.http.get<Project[]>(`${this.baseUrl}/organisations/${params.organisationId}/projects`);
  }

  public createProjectInOrganisation(organisationId: string, project: { name: string, organisationId: string, fileIds: string[] }) {
    return this.http.post<Project>(`${this.baseUrl}/organisations/${organisationId}/projects`, project);
  }

  public updateProjectInOrganisation(organisationId: string, project: { name: string, id: string, description: string | null, code: string | null, fileIds: string[] }) {
    return this.http.put<Project>(`${this.baseUrl}/organisations/${organisationId}/projects/${project.id}`, { name: project.name, code: project.code, description: project.description, fileIds: project.fileIds });
  }

  public deleteProjectInOrganisation(organisationId: string, projectId: string) {
    return this.http.delete<void>(`${this.baseUrl}/organisations/${organisationId}/projects/${projectId}`);
  }

  public getProjectDesign(organisationId: string, projectId: string) {
    return this.http.get<Design[]>(`${this.baseUrl}/organisations/${organisationId}/projects/${projectId}/designs`);
  }

  public createProjectDesign(params: { organisationId: string, projectId: string, featureCollection: any }) {
    return this.http.post<Project>(`${this.baseUrl}/organisations/${params.organisationId}/projects/${params.projectId}/designs`, { featureCollection: params.featureCollection });
  }

  public requestOrganisationsSubscription(params: { subscriptionTypeName: string, organisationId: string, customAvailableSlots: number | null }) {
    return this.http.post<MrKLICSubscription>(`${this.baseUrl}/organisations/${params.organisationId}/subscriptions`, params);
  }

  public listOrderItemsInOrganisation(params: { organisationId: string }) {
    return this.http.get<OrderItem[]>(`${this.baseUrl}/organisations/${params.organisationId}/order-items`);
  }

  public getOrganisationRiskAssessment(organisationId: string) {
    return this.http.get<RiskAssessment>(`${this.baseUrl}/organisations/${organisationId}/risk-assessment`);
  }

  public createOrganisationRiskAssessment(organisationId: string, riskAssessment: { [key: string]: number }) {
    return this.http.post<RiskAssessment>(`${this.baseUrl}/organisations/${organisationId}/risk-assessment`, { riskAssessment });
  }

  public updateOrganisationRiskAssessment(params: { organisationId: string, riskAssessmentId: string, riskAssessment: { [key: string]: number } }) {
    return this.http.put<RiskAssessment>(`${this.baseUrl}/organisations/${params.organisationId}/risk-assessment`, { riskAssessmentId: params.riskAssessmentId, riskAssessment: params.riskAssessment });
  }

  public validateKadasterLogin(organisation: Organisation, user: User) {
    return this.http.post<any>(`${this.baseUrl}/organisations/${organisation.id}/kadaster-login-validation`, { organisation, user });
  }

  // Credits
  public getCreditBalance(organisationId: string) {
    return this.http.get<number>(`${this.baseUrl}/organisations/${organisationId}/credit-balance`);
  }

  public addCredits(organisationId: string, credits: number) {
    return this.http.post(`${this.baseUrl}/organisations/${organisationId}/credits`, { credits });
  }


  // slots usage based on upload-status
  public listUploadStatistics(organisationId: string) {
    return this.http.get<{
      sources: any[],
      periods: any[],
    }>(`${this.baseUrl}/organisations/${organisationId}/upload-statistics`);
  }

  public getCreditUsageForCurrentPeriod(organisationId: string) {
    return this.http.get<{ creditUsage: any[], creditLedgerEntries: any[], periodStart: string, periodEnd: string }>(`${this.baseUrl}/organisations/${organisationId}/credit-usage`);
  }

  // Subscription Type
  public listSubscriptionTypes() {
    return this.http.get<SubscriptionType[]>(`${this.baseUrl}/subscription-types`);
  }

  /* Users */
  public listRoles() {
    return this.http.get<Role[]>(`${this.baseUrl}/roles`);
  }

  /* Users */
  public listUsers() {
    return this.http.get<User[]>(`${this.baseUrl}/users`);
  }

  public createUser(user: UpdateUserParams) {
    return this.http.post<User>(`${this.baseUrl}/users`, user);
  }

  public updateUser(userId: string, user: Partial<UpdateUserParams>) {
    return this.http.put<User>(`${this.baseUrl}/users/${userId}`, user);
  }

  public deleteUser(userId: string) {
    return this.http.delete<void>(`${this.baseUrl}/users/${userId}`);
  }

  public getUserById(userId: string) {
    return this.http.get<User[]>(`${this.baseUrl}/users/${userId}`);
  }

  public emailExists(email: string) {
    return this.http.get<boolean>(`${this.baseUrl}/users/email/${email}`);
  }

  /* Uploads */
  public listUploadsForAdmin(params: { q?: string }) {
    return this.http.get<Upload[]>(`${this.baseUrl}/uploads`, {
      params: params
    });
  }

  public deleteUpload(uploadId: string) {
    return this.http.delete<void>(`${this.baseUrl}/uploads/${uploadId}`);
  }

  public archiveUpload(uploadId: string) {
    return this.http.put<Upload>(`${this.baseUrl}/uploads/${uploadId}/archive`, {});
  }

  public unarchiveUpload(uploadId: string) {
    return this.http.put<Upload>(`${this.baseUrl}/uploads/${uploadId}/unarchive`, {});
  }

  public assignUploadToProject(params: { uploadId: string, projectId: string | null }) {
    return this.http.post<Upload>(`${this.baseUrl}/uploads/${params.uploadId}/projects/${params.projectId}`, null);
  }


  public updateUpload(params: { uploadId: string, uploadAttributes: Partial<Upload> }) {
    return this.http.put<Upload>(`${this.baseUrl}/uploads/${params.uploadId}`, params.uploadAttributes);
  }

  public uploadKlicFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    return this.http.post<any>(`${this.baseUrl}/uploads`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      this.utilsService.upload()
    );
  }

  public async uploadDesign(file: File, selectedUploadTypeUrl: string) {
    const formData = new FormData();
    formData.append("file", file);

    const data = await firstValueFrom(this.http.post<FeatureCollection>(`${this.baseUrl}/uploads/designs?type=${selectedUploadTypeUrl}`, formData));
    return data;
  }

  public listTodosForUpload(params: { uploadId: string }) {
    return this.http.get<Todo[]>(`${this.baseUrl}/uploads/${params.uploadId}/todos`);
  }

  public createTodo(todo: Omit<Todo, 'id' | 'createdAt' | 'updatedAt'>) {
    return this.http.post<Todo>(`${this.baseUrl}/uploads/${todo.uploadId}/todos`, { todo });
  }

  public updateTodo(id: string, todo: Partial<Todo>) {
    return this.http.patch<Todo>(`${this.baseUrl}/uploads/${todo.uploadId}/todos/${id}`, { todo });
  }

  public deleteTodo(uploadId: string, todoId: string) {
    return this.http.delete<Todo>(`${this.baseUrl}/uploads/${uploadId}/todos/${todoId}`);
  }

  public listTodosInArea(uploadId: string, params: {
    lat: number,
    lng: number,
    buffer: number
  }) {
    return this.http.get<Todo[]>(`${this.baseUrl}/uploads/${uploadId}/todos/area`, {
      params
    });
  }

  public listOtherActiveUploadsInProject(uploadId: string, params: {
    lat: number,
    lng: number,
    buffer: number
  }) {
    return this.http.get<Upload[]>(`${this.baseUrl}/uploads/${uploadId}/other-active-uploads`, {
      params
    });
  }

  public createAndSendShareUrl(uploadId: string, params: {
    userIds: string[],
    redirectTo: string
  }) {
    return this.http.post<any>(`${this.baseUrl}/uploads/${uploadId}/share-url`, {
      ...params
    });
  }

  /* KLIC-data */
  public listKlicAttachments(uploadId: string) {
    return this.http.get<KlicAttachment[]>(`${this.baseUrl}/klic/${uploadId}/klic-attachments`);
  }

  public listKlicExtraDetails(uploadId: string) {
    return this.http.get<ExtraDetails[]>(`${this.baseUrl}/klic/${uploadId}/klic-extra-detail`);
  }

  public listKlicMetaData(uploadId: string) {
    return this.http.get<KlicMetadataStateModel>(`${this.baseUrl}/klic/${uploadId}/metadata`);
  }

  public listProjectReportData(organisationId: string, projectId: string) {
    return this.http.get<ProjectReportData>(`${this.baseUrl}/organisations/${organisationId}/projects/${projectId}/report-data`);
  }

  public listKlicNetworkObjects(uploadId: string, params: {
    lat: number,
    lng: number,
    buffer: number,
    utilityNetworks: string[]
    objectsToQuery: string[]
  }) {
    return this.http.get<(KlicNetworkObject | KlicPrecaution)[]>(`${this.baseUrl}/klic/${uploadId}/network-objects`, {
      params
    });
  }

  public getBasemapDownloadUrl(params: { uploadId: string }) {
    return `${this.baseUrl}/uploads/${params.uploadId}/basemap_compressed`;
  }

  public getLeveringsbriefDownloadUrl(params: { uploadId: string }) {
    return `${this.baseUrl}/uploads/${params.uploadId}/leveringsbrief`;
  }

  public getKlicFileDownloadUrl(params: { uploadId: string, fileId: string }) {
    return `${this.baseUrl}/uploads/${params.uploadId}/download/${params.fileId}/attachment`;
  }

  public getKlicDownloadUrl(uploadId: string) {
    return this.http.get(`${environment.apiBaseUrl}/api/v1/uploads/${uploadId}/download`, { responseType: 'blob' });
  }

  public getKlicFilesZipUrl(uploadId: string) {
    return `${this.baseUrl}/klic/${uploadId}/files`;
  }

  public getExportsUrl(organisationApiKey: string, wmsWfs: 'wms' | 'wfs', projectId?: string) {
    if (projectId) {
      return `${environment.apiBaseUrl}/exports/${organisationApiKey}/projects/${projectId}/klic/${wmsWfs}`;
    }
    return `${environment.apiBaseUrl}/exports/${organisationApiKey}/klic/${wmsWfs}?`;
  }

  /* KVK-API */
  public searchKvkCompanies(params: {
    searchTerm: string,
  }) {
    return this.http.get<KvK.Company[]>(`${this.baseUrl}/kvk`, {
      params: {
        searchTerm: params.searchTerm
      }
    });
  }

  public getKvkCompanyAddress(params: {
    vestigingsnummer: string,
  }) {
    return this.http.get<KvKAddress>(`${this.baseUrl}/kvk/${params.vestigingsnummer}/address`);
  }

  public export(url: string) {
    return this.http.get(`${url}`, { responseType: 'blob' });
  }

  public emailFileExport(params: {
    exportType: ExportTypes,
    selectedUploadId: string,
    exportConfiguration?: ExportConfiguration,
    exportFilters?: ExportFilter[],
    outputFormat: FMEDownloadTypes,
    emailTo: string,
    userName: string,
    runLegacy: boolean
  }) {
    return this.http.get(`${this.baseUrl}/uploads/${params.selectedUploadId}/download-fme`, {
      params: {
        outputFormat: params.outputFormat,
        emailTo: params.emailTo,
        exportType: params.exportType,
        exportConfiguration: params.exportConfiguration || '',
        exportFilters: params.exportFilters || [],
        userName: params.userName,
        runLegacy: params.runLegacy
      }
    });
  }

  public createRiskGrid(selectedUploadId: string) {
    return this.http.post(`${this.baseUrl}/uploads/${selectedUploadId}/risk-grid`, {});
  }

  public deleteRiskGrid(selectedUploadId: string) {
    return this.http.delete(`${this.baseUrl}/uploads/${selectedUploadId}/risk-grid`);
  }

  /* Orders */
  public createOrder(body: { organisationId: string, orderedById: string, orderItems: any[], useOrganisationKadasterLogin: boolean, executionDate: string }) {
    return this.http.post<{ checkoutUrl: string }>(`${this.baseUrl}/orders`, body);
  }

  public getOrder(orderId: string) {
    return this.http.get<any>(`${this.baseUrl}/orders/${orderId}`);
  }

  public triggerOrderItemProcessing() {
    return this.http.post<any>(`${this.baseUrl}/orders/manual-triggers`, null);
  }

  public async buildPolygon(featureCollection: FeatureCollection) {
    const data = await firstValueFrom(this.http.post<FeatureCollection>(`${this.baseUrl}/orders/request-area`, {
      featureCollection
    }));
    return data;
  }

  public async chopArea(featureCollection: FeatureCollection, size: number, snap_to_area: boolean) {
    const data = await firstValueFrom(this.http.post<FeatureCollection>(`${this.baseUrl}/orders/chopped-request-area`, {
      featureCollection,
      size,
      snap_to_area
    }));
    return data;
  }


  /* Order items */
  public listOrderItems() {
    return this.http.get<OrderItem[]>(`${this.baseUrl}/order-items`)
  }

  public triggerKadasterRequest(orderItemId: string) {
    return this.http.get<any>(`${this.baseUrl}/order-items/${orderItemId}/trigger-kadaster-request`);
  }

  /* Files */

  public uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const upload$ = this.http.post<MrklicFile>(`${this.baseUrl}/files`, formData, {
      reportProgress: true,
      observe: 'events'
    })

    return upload$.pipe(
      this.utilsService.upload()
    );
  }

  public deleteFile(fileId: string) {
    return this.http.delete(`${this.baseUrl}/files/${fileId}`)
  }

  public getFileDownloadUrl(fileId: string) {
    return `${this.baseUrl}/files/${fileId}`;
  }

  public referral(opts: { name: string, email: string, friendName: string, friendEmail: string, phone: string }) {
    return this.http.post(`${this.baseUrl}/referral`, opts);
  }

  public logActivity(userId: string) { 
    return this.http.post(`${this.baseUrl}/users/${userId}/activity`, {});
  }

  /* Example Files */

  public getExportExampleUrl(exportFormat: string) {
    return `${this.baseUrl}/example-files?exportFormat=${exportFormat}`;
  }
}



