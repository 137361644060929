import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";

import { AppStateModel, SetExportConfiguration, SetGoogleAdsConsent, SetKlicViewMode, SetShowEvNotification, UpdateLastReadDate, UpdateOrderScreenLayers } from "./models/app.state.model";
import { StateNames } from "./models/state-names.enum";
import { ExportConfigurations, ExportFormats, FMEDownloadTypes } from "../interfaces/export-types.enum";

const defaultAppState = {
    klicViewMode: 'list' as 'list',
    showEvNotification: true,
    googleAdsConsent: false,
    openConsentWindow: true,
    exportConfiguration: {
        selectedDownloadType: { 
            format: ExportFormats.DWG,
            url: FMEDownloadTypes.dwg,
            fileType: 'zip',
            mail: true,
            exportConfigurations: JSON.parse(JSON.stringify(ExportConfigurations))
        },
        selectedDownloadConfiguration: 'nlcs',
         selectedDownloadFilters: ['punten', 'lijnen', 'vlakken', 'luchtfoto', 'extra_geometrie', 'extra_detail']
    },
    orderScreenLayers: {
        activeKLICmeldingen: false,
        archivedKLICmeldingen: false
    },
    lastReadNewsUpdate: 0
};

@State<AppStateModel>({
    name: StateNames.app_v4,
    defaults: defaultAppState
})
@Injectable()
export class AppState {
    @Selector([AppState])
    static klicViewMode(state: AppStateModel) {
        return state.klicViewMode;
    }

    @Selector([AppState])
    static showEvNotification(state: AppStateModel) {
        return state.showEvNotification;
    }

    @Selector([AppState])
    static googleAdsConstent(state: AppStateModel) {
      return state.googleAdsConsent;
    }

    @Selector([AppState])
    static openConsentWindow(state: AppStateModel) {
      return state.openConsentWindow;
    }

    @Selector([AppState])
    static exportConfiguration(state: AppStateModel) {
      return state.exportConfiguration;
    }

    @Selector([AppState])
    static orderScreenLayers(state: AppStateModel) {
        return state.orderScreenLayers;
    }

    @Selector([AppState])
    static lastReadNewsUpdate(state: AppStateModel) {
        return state.lastReadNewsUpdate;
    }

    constructor() { }

    @Action(SetExportConfiguration)
    setExportConfiguration(ctx: StateContext<AppStateModel>, action: SetExportConfiguration) {
        ctx.patchState({
            exportConfiguration: action.payload.exportConfiguration
        })
    }

    @Action(SetKlicViewMode)
    setKlicViewMode(ctx: StateContext<AppStateModel>, action: SetKlicViewMode) {
        ctx.patchState({
            klicViewMode: action.payload.klicViewMode
        })
    }

    @Action(SetShowEvNotification)
    setShowEvNotification(ctx: StateContext<AppStateModel>, action: SetShowEvNotification) {
        ctx.patchState({
            showEvNotification: action.payload.showEvNotification
        })
    }

    @Action(SetGoogleAdsConsent)
    setGoogleAdsConsent(ctx: StateContext<AppStateModel>, action: SetGoogleAdsConsent) {
      ctx.patchState({
        googleAdsConsent: action.payload.adConsentGiven,
        openConsentWindow: false
      });
    }
    
    @Action(UpdateOrderScreenLayers)
    updateOrderScreenLayers(ctx: StateContext<AppStateModel>, action: UpdateOrderScreenLayers) {
        ctx.patchState({
            orderScreenLayers: {
                activeKLICmeldingen: action.payload.activeKLICmeldingen,
                archivedKLICmeldingen: action.payload.archivedKLICmeldingen
            }
        })
    }

    @Action(UpdateLastReadDate)
    UpdateLastReadDate(ctx: StateContext<AppStateModel>, action: UpdateLastReadDate) {
        ctx.patchState({
            lastReadNewsUpdate: action.payload.lastReadNewsUpdate
        })
    }
}
