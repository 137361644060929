import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationError } from '../interfaces/application-error.interface';
import { environment } from 'src/environments/environment';
import { RollbarService } from '../services/rollbar.service';

@Injectable()
export class GenericErrorHandler implements ErrorHandler {
  constructor(
    // Because the GeneralErrorHandler is created before the providers, we’ll have to use the Injector to get them.
    private injector: Injector,
    private zone: NgZone,
    private rollbar: RollbarService
  ) { }

  public handleError(error: any) {
    console.error(error);
    this.handleChunkLoadFailedError(error);

    /* This is a fix for handling uncaught promise errors */
    if (error.rejection) {
      error = error.rejection as Error;
    }

    if (environment.rollbar.enable) {
      this.rollbar.rollbar.error(error);
    }

    this.notifyError(error);
  }


  public handleChunkLoadFailedError(err: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(err.message)) {
      alert("Nieuwe versie beschikbaar. Update naar de nieuwe versie");
      window.location.reload();
    }
  }

  private notifyError(error: Error | HttpErrorResponse | any) {
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        return this.openSnackbar(`Geen internet beschikbaar.`);
      }


      if (error.status === 400) {
        // All custom errors have a bad request error.
        if (error.error.error === 'Bad Request') {
          return this.openSnackbar(error.error.message);
        } else {
          return this.openSnackbar(`Verkeerd ingevuld formulier.`)
        }
      }

      if (error.status === 401) {
        return this.openSnackbar(`Incorrecte inloggegevens.`);
      }

      if (error.status === 403) {
        return this.openSnackbar(`U heeft hier geen toegang toe.`);
      }

      if (error.status === 500) {
        return this.openSnackbar(`De server kan uw verzoek op dit moment niet verwerken.`);
      }
    }

    if (error instanceof ApplicationError) {
      return this.openSnackbar(error.message);
    }

    this.openSnackbar(`Er is een fout opgetreden in de applicatie.`);
  }

  private openSnackbar(message: string, action = `OK`) {
    const snackbar = this.injector.get(MatSnackBar);

    this.zone.run(() => {
      snackbar.open(message, action, { duration: 4.5 * 1000 });
    });
  }
}
