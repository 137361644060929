import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    UnauthorizedComponent,
    LogoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage
  ],

})
export class PagesModule { }
