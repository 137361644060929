import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { CoreModule } from '../core.module';

@Injectable({
    providedIn: CoreModule
})
export class LeadInfoService {

    constructor() {
    }

    public addLeadinfoTag() {
        if (environment.leafinfo.enable === false) {
            return;
        }
        //@ts-ignore
        (function (l, e, a, d, i, n, f, o) {
            //@ts-ignore
            if (!l[i]) {
                //@ts-ignore
                l.GlobalLeadinfoNamespace = l.GlobalLeadinfoNamespace || [];
                //@ts-ignore
                l.GlobalLeadinfoNamespace.push(i); l[i] = function () { (l[i].q = l[i].q || []).push(arguments) }; l[i].t = l[i].t || n;
                //@ts-ignore
                l[i].q = l[i].q || []; o = e.createElement(a); f = e.getElementsByTagName(a)[0]; o.async = 1; o.src = d; f.parentNode.insertBefore(o, f);
            }
        }(window, document, 'script', 'https://cdn.leadinfo.net/ping.js', 'leadinfo', environment.leafinfo.tag));
    }

}



