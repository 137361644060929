export enum MapMode {
    inspect = 'inspect',
    measure = 'measure',
    draw_trench = 'draw_trench',
    streetsmart = 'streetsmart'
}

export interface MaintenanceLayers {
    eisVoorzorgsmaatregel: boolean;
    klicMeldingenInProject: boolean;
    extraDetailInfo: boolean;
    annotations: boolean,
    maatvoeringen: boolean;
    dieptes: boolean;
    design: boolean;
    risk: boolean;
    todo: boolean;
}

export interface MapStateModel {
    mapMode: MapMode;
    center: number[];
    zoom: number; 
    showGPSlocation: boolean;
    layers: { 
        basemaps: string[]; 
        klicLayerFilter: string[];
        maintenanceLayers: MaintenanceLayers;
    };
}
export class SetMapMode {
    static readonly type = '[Map] Set Map Mode'
    constructor(public payload: { mapMode: MapMode }) { }
}

export class SetCenterAndZoom {
    static readonly type = '[Map] Set Center And Zoom'
    constructor(public payload: { center: number[], zoom: number}) { }
}

export class ResetCenterAndZoom {
    static readonly type = '[Map] Reset Center And Zoom'
    constructor() { }
}

export class UpdateLayers {
    static readonly type = '[Map] UpdateLayers'
    constructor(public payload: { basemaps?: string[], klicLayerFilter?: string[], maintenanceLayers?: MaintenanceLayers }) { }
}


export class EnableGpsWatch {
    static readonly type = '[Map] EnableGpsWatch'
    constructor() { }
}

export class DisableGPSWatch {
    static readonly type = '[Map] DisableGPSWatch'
    constructor() { }
}