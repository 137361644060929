import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";
import dayjs from "dayjs";

import { ResetShop, SetShoppingCartContent, ShopStateModel } from "./models/shop.state.model";
import { StateNames } from "./models/state-names.enum";

const defaultShopState = {
    type: 'graafmelding' as const,
    activities: [],
    startDate: dayjs().add(3, 'days').toDate().toString(),
    endDate: dayjs().add(3, 'days').toDate().toString(),
    geometries: [],
    addedOn: new Date().toString(),
    closestAddressIds: [],
    notitie: '',
    reference: '',
    projectId: null
};

@State<ShopStateModel>({
    name: StateNames.shop,
    defaults: defaultShopState
})
@Injectable()
export class ShopState {
    @Selector([ShopState])
    static type(state: ShopStateModel) {
        return state.type;
    }

    @Selector([ShopState])
    static activities(state: ShopStateModel) {
        return state.activities;
    }

    @Selector([ShopState])
    static reference(state: ShopStateModel) {
        return state.reference;
    }

    @Selector([ShopState])
    static notitie(state: ShopStateModel) {
        return state.notitie;
    }

    @Selector([ShopState])
    static startDate(state: ShopStateModel) {
        return state.startDate;
    }

    @Selector([ShopState])
    static endDate(state: ShopStateModel) {
        return state.endDate;
    }

    @Selector([ShopState])
    static geometries(state: ShopStateModel) {
        return state.geometries;
    }

    @Selector([ShopState])
    static closestAddressIds(state: ShopStateModel) {
        return state.closestAddressIds;
    }

    @Selector([ShopState])
    static projectId(state: ShopStateModel) {
        return state.projectId;
    }

    @Selector([ShopState])
    static shouldReset(state: ShopStateModel) {
        return dayjs(state.addedOn).diff(dayjs(), 'hours') > 12;
    }

    @Selector([ShopState])
    static orderItems(state: ShopStateModel) {
        return state.geometries.map((geometry, index) => {
            return {
                type: state.type,
                activities: state.activities,
                startDate: state.startDate,
                endDate: state.endDate,
                geometry: geometry,
                closestAddressId: state.closestAddressIds[index],
                notitie: state.notitie,
                reference: state.reference,
                projectId: state.projectId
            }
        })
    }


    constructor() { }

    @Action(SetShoppingCartContent)
    setShoppingCartState(ctx: StateContext<ShopStateModel>, action: SetShoppingCartContent) {
        ctx.patchState(action.payload);
    }

    @Action(ResetShop)
    resetShop(ctx: StateContext<ShopStateModel>, action: ResetShop) {
        ctx.setState(defaultShopState);
    }

}
