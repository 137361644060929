import { Organisation } from "../../interfaces/organisation.interface";

export interface OrganisationsStateModel {
  organisations: {
    [key: string]: Organisation;
  }
}

export class ListOrganisations {
  static readonly type = '[Organisations] List';
}

export class GetOrganisationById {
  static readonly type = '[Organisations] GetById';
  constructor(public payload: { organisationId: string }) {
  }
}

export class FirstRun {
  static readonly type = '[Organisations] Create';
  constructor(public payload: {
    estimatedYearlyKlics: string;
    kvkNumber: string;
    name: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    country: string;
    telephone: string;
  }) { }
}

export class UpdateOrganisation {
  static readonly type = '[Organisations] Update';
  constructor(public payload: { organisationId: string, organisation: Partial<Organisation>, fileIds?: string[] }) { }
}
