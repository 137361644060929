import { KlicContact } from "../../interfaces/klic-contact.interface";
import { KlicBeheerder, KlicUtilityNetwork } from "../../interfaces/klic-utility-network.interface"
import { KlicPrecaution } from "./selection.state.model";

export interface KlicMetadataStateModel {
    utilityNetworks: KlicUtilityNetwork[];
    themes: string[];
    beheerders: KlicBeheerder[];
    precautions: KlicPrecaution[];
    contacts: KlicContact[];
}

export class ListKlicMetadata {
    static readonly type = '[KlicMetadata] ListKlicMetadata'
    constructor(public payload: { uploadId: string }) { }
}



