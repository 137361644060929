import { Project } from "../../interfaces/project.interface";

export interface ProjectsStateModel {
    projects: {
        [key: string]: Project;
    },
}

export class ListProjects {
    static readonly type = '[Project] List All In project';
    constructor(public payload: { organisationId: string }) { }
}

export class CreateProject {
    static readonly type = '[Project] Create';
    constructor(public payload: { organisationId: string, name: string, id: string, fileIds: string[] }) { }
}

export class DeleteProject {
    static readonly type = '[Project] Delete';
    constructor(public payload: { organisationId: string, projectId: string }) { }
}
export class UpdateProject {
    static readonly type = '[Project] Update';
    constructor(public payload: { name: string, id: string, description: string | null, organisationId: string, code: string | null, fileIds: string[] }) { }
}

export class CreateProjectDesign {
    static readonly type = '[Project] CreateProjectDesing';
    constructor(public payload: {
        organisationId: string, 
        projectId: string, 
        featureCollection: any
      }) {}
}