import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action,  } from "@ngxs/store";
import { ApiService } from "../services/api.service";
import { firstValueFrom } from "rxjs";
import { KlicMetadataStateModel, ListKlicMetadata } from "./models/klic-metadata.state.model";

const defaultKlicMetadata = {
  utilityNetworks: [],
  themes: [],
  beheerders: [],
  precautions: [],
  contacts: []
};

@State<KlicMetadataStateModel>({
  name: 'metadata',
  defaults: defaultKlicMetadata
})
@Injectable()
export class KlicMetadataState {
  @Selector([KlicMetadataState])
  static utilityNetworks(state: KlicMetadataStateModel) {
    return state.utilityNetworks;
  }

  @Selector([KlicMetadataState])
  static beheerders(state: KlicMetadataStateModel) {
    return state.beheerders;
  }

  @Selector([KlicMetadataState])
  static themes(state: KlicMetadataStateModel) {
    return state.themes;
  }

  @Selector([KlicMetadataState])
  static precautions(state: KlicMetadataStateModel) {
    return state.precautions;
  }

  @Selector([KlicMetadataState])
  static contacts(state: KlicMetadataStateModel) {
    return state.contacts;
  }

  @Selector([KlicMetadataState])
  static hasExtraPrecaution(state: KlicMetadataStateModel) {
    return state.precautions.length > 0;
  }

  constructor(
    private apiService: ApiService
  ) { }

  @Action(ListKlicMetadata)
  async listKlicMetadata(ctx: StateContext<KlicMetadataStateModel>, action: ListKlicMetadata) {
    const metadata = await firstValueFrom(this.apiService.listKlicMetaData(action.payload.uploadId));

    ctx.setState({ 
        utilityNetworks: metadata.utilityNetworks,
        beheerders: metadata.beheerders,
        themes: metadata.themes,
        precautions: metadata.precautions,
        contacts: metadata.contacts
    });
  }

}
