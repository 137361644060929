import { Injectable } from '@angular/core';

import { CoreModule } from '../core.module';
import { Store } from '@ngxs/store';
import { GetOrganisationById } from '../states/models/organisations.state.model';
import { firstValueFrom } from 'rxjs';
import { AuthState } from '../states/auth.state';
import { ListUsersInOrganisation } from '../states/models/users.state.model';
import { ListProjects } from '../states/models/projects.state.model';

@Injectable({
  providedIn: CoreModule,
})
export class MyOrganisationResolver  {
  constructor(
    private store: Store,
  ) { }

  public async resolve(): Promise<boolean> {
    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      const currentUser = this.store.selectSnapshot(AuthState.currentUser)!;
      if (currentUser.organisationId === null) {
        return true;
      }
      await firstValueFrom(this.store.dispatch(new GetOrganisationById({ organisationId: currentUser.organisationId })));
      await firstValueFrom(this.store.dispatch(new ListUsersInOrganisation({ organisationId: currentUser.organisationId })));
      await firstValueFrom(this.store.dispatch(new ListProjects({ organisationId: currentUser.organisationId })));
    }

    return true;
  }
}
