import { Upload } from "../../interfaces/upload.interface";

export interface UploadsStateModel {
  uploads: {
    [key: string]: Upload;
  },
  selectedUploadId: string | null;
}

export class ListUploadsForAdmin {
  static readonly type = '[Uploads] List All';
  constructor(public payload: {q?: string}) { }
}

export class ListUploadsForOrganisation {
  static readonly type = '[Uploads] List';
  constructor(public payload: { organisationId: string, params: {
    statusses?: string[],
    projectId?: string,
    q?: string,
    aanvraagSoort?: string,
    evCriteria?: string,
    keepActive?: boolean
  } }) { }
}

export class SetSelectedUploadId {
  static readonly type = '[Uploads] Set Selected';
  constructor(public payload: { uploadId: string }) { }
}

export class DeleteUpload {
  static readonly type = '[Uploads] Delete';
  constructor(public payload: { uploadId: string }) { }
}

export class DeleteRisicogrid {
  static readonly type = '[Uploads] DeleteRisicogrid';
  constructor(public payload: { uploadId: string }) { }
}

export class AssignUploadToProject {
  static readonly type = '[Uploads] AssignToProject';
  constructor(public payload: { uploadId: string, projectId: string | null }) { }
}

export class UpdateUpload {
  static readonly type = '[Uploads] UpdateUpload';
  constructor(public payload: { uploadId: string, uploadAttributes: Partial<Upload> }) { }
}
