import { ExportFormats, FMEDownloadTypes, ExportConfigurations, ExportFilters } from "../../interfaces/export-types.enum";

export interface AppStateModel {
    // mapMode: MapMode;
    klicViewMode: 'table' | 'list' | 'map';
    showEvNotification: boolean;
    googleAdsConsent: boolean;
    openConsentWindow: boolean;
    exportConfiguration: {
        selectedDownloadType: {
            format: ExportFormats;
            url: FMEDownloadTypes;
            fileType: string;
            mail: boolean;
            exportConfigurations: typeof ExportConfigurations[];
        }
        selectedDownloadConfiguration: string;
        selectedDownloadFilters: string[];
    };
    orderScreenLayers: {
        activeKLICmeldingen: boolean;
        archivedKLICmeldingen: boolean;
    },
    lastReadNewsUpdate: number;
}

export class SetExportConfiguration {
    static readonly type = '[App] Set Export Configuration'
    constructor(public payload: {
        exportConfiguration: {
            selectedDownloadType: {
                format: ExportFormats;
                url: FMEDownloadTypes;
                fileType: string;
                mail: boolean;
                exportConfigurations: typeof ExportConfigurations[];
            }
            selectedDownloadConfiguration: string
            selectedDownloadFilters: string[]
        }
    }) { }
}

export class SetKlicViewMode {
    static readonly type = '[App] Set Klic View Mode'
    constructor(public payload: { klicViewMode: 'table' | 'list' | 'map' }) { }
}

export class SetShowEvNotification {
    static readonly type = '[App] Set Show EV Notification'
    constructor(public payload: { showEvNotification: boolean }) { }
}


export class SetGoogleAdsConsent {
    static readonly type = '[App] SetGoogleAdsConsent';
    constructor(public payload: {
        adConsentGiven: boolean
    }) { }
}

export class UpdateOrderScreenLayers {
    static readonly type = '[App] UpdateOrderScreenLayers'
    constructor(public payload: { activeKLICmeldingen: boolean, archivedKLICmeldingen: boolean }) { }
}

export class UpdateLastReadDate {
    static readonly type = '[App] UpdateLastReadDate'
    constructor(public payload: { lastReadNewsUpdate: number }) { }
}