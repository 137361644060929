import { Injectable } from "@angular/core";
import Rollbar from "rollbar";
import { environment } from "src/environments/environment";
import { VERSION } from "../config";
import { CoreModule } from "../core.module";

@Injectable({
    providedIn: CoreModule
})
export class RollbarService {
    public settings = {
        accessToken: environment.rollbar.token,
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: environment.name,
        enabled: environment.rollbar.enable,
        itemsPerMinute: 5,
        captureIp: "anonymize" as any,
        code_version: VERSION,
        payload: {
            code_version: VERSION,
            codeVersion: VERSION
        }
    };

    public rollbar = new Rollbar(this.settings);

    constructor() {
    }


}