import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { AuthUser } from '../states/models/auth.state.model';

@Injectable({
  providedIn: CoreModule
})
export class AuthService {
  private baseUrl = environment.apiBaseUrl + '/api/v1';
  constructor(private http: HttpClient) {
  }

  public profile() {
    return this.http.get<any>(`${this.baseUrl}/auth/profile`);
  }

  public login(params: {email: string, password: string}) {
    return this.http.post<any>(`${this.baseUrl}/auth/login`, params).pipe(
      map(authDetails => {
        const decodedToken: AuthUser = jwt_decode(authDetails.access_token);
        return {
          token: authDetails.access_token,
          currentUser: decodedToken
        };
      })
    )
  }

  public changePassword(params: {email: string, password: string}) {
    return this.http.post<any>(`${this.baseUrl}/auth/change-password`, params).pipe(
      map(authDetails => {
        const decodedToken: AuthUser = jwt_decode(authDetails.access_token);
        return {
          token: authDetails.access_token,
          currentUser: decodedToken
        };
      })
    )
  }

  public sendMagicLoginUrl(params: {email: string , redirectTo?: string}) {
    return this.http.post<any>(`${this.baseUrl}/auth/magic-login`, {destination: params.email, redirectTo: params.redirectTo});
  }

  public register(params: {firstName: string, lastName: string, email: string, password: string, roles: any[]}) {
    return this.http.post<any>(`${this.baseUrl}/users`, params)
  }

  public firstRun(params: {estimatedYearlyKlics: string, kvkNumber: string, name: string, street: string, houseNumber: string, postalCode: string, city: string, country: string, telephone: string}) {
    return this.http.post<any>(`${this.baseUrl}/organisations`, params)
  }

  public lostPassword(params:{email: string}) {
    return this.http.post<any>(`${this.baseUrl}/auth/lost-password`, params)
  }

  public resetPassword(params:{password: string, token: string, userId: string}) {
    return this.http.post<any>(`${this.baseUrl}/auth/reset-password`, params)
  }

  public magicLogin(params: {url: string }) {
    return this.http.get<any>(`${environment.apiBaseUrl}${params.url}`).pipe(
      map(authDetails => {
        const decodedToken: AuthUser = jwt_decode(authDetails.access_token);
        return {
          token: authDetails.access_token,
          currentUser: decodedToken
        };
      })
    )
  }

}

